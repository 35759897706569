<template>
	<div class="container text-center">
		<div class="row align-items-center justify-content-center">
			<div v-for="(migaText, index) in migasText" :key="migaText" class="col">
				<div class="migas-texto" :class="{ migaTextoEnabled: index <= posicion }">
					{{ migaText }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="progress">
					<div
						class="progress-bar"
						role="progressbar"
						:style="{
							width: (100 / (migasText.length - 1)) * posicion + '%',
						}"
						aria-valuenow="15"
						aria-valuemin="0"
						aria-valuemax="100" />
				</div>
			</div>
		</div>
		<div class="row align-items-center justify-content-center">
			<div v-for="(migaText, index) in migasText" :key="migaText" class="col">
				<div class="migas-circulo" :class="{ migaEnabled: index <= posicion }">&nbsp;</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BaseMigas',
		props: {
			migasText: {
				type: Array,
				default: () => [],
			},
			posicion: {
				type: Number,
				default: () => 0,
			},
		},
	};
</script>

<style lang="css" scoped>

	.migas-texto {
		color: #9b9b9b;
		font-size: 0.6rem;
	}

	.migaTextoEnabled {
		color: var(--bs-secondary) !important;
	}

	.migaEnabled {
		background-color: var(--bs-secondary) !important;
	}

	.migas-circulo {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		position: relative;
		background-color: #9b9b9b;
		width: 1rem;
		height: 1rem;
		margin-left: auto;
		margin-right: auto;
		border-radius: var(--bs-border-radius-xl);
	}

	.progress {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		position: relative;
		top: 0.6rem;
		margin-left: auto;
		margin-right: auto;
		height: 2px;
		max-width: 76%;
		background-color: #9b9b9b;
	}

	.progress-bar {
		background-color: var(--bs-secondary);
	}

	@media only screen and (min-width: 962px) {
		.migas-texto,
		.migas-texto-disable {
			font-size: 1rem;
		}
		.progress {
			top: 1.1rem;
			height: 4px;
		}

		.migas-circulo,
		.migas-circulo-disable {
			width: 2rem;
			height: 2rem;
		}
	}
</style>
