<template>
	<teleport to="body">
		<modal-base
			:show="modalActive"
			:title="t('rooming.suplementoTitulo')"
			:align-footer="'center'"
			@on-close="closeModal">
			<template #body>
				<span class="text-primary">{{ t('rooming.suplementoMensaje') }}</span>
			</template>
			<template #footer>
				<button class="text-primary btn-link" @click="continuar">{{ t('rooming.continuar') }}</button>
				<button class="btn btn-primary btn-guardar" @click="volver">{{ t('rooming.volver') }}</button>
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import { useI18n } from 'vue-i18n';
	import ModalBase from '@/components/modales/ModalBase.vue';

	const { t } = useI18n();

	defineProps({
		modalActive: {
			type: Boolean,
			required: true,
		},
	});

	const emits = defineEmits(['close-modal']);

	const continuar = () => {
		closeModal();
	};

	const volver = () => {
		closeModal();
	};

	const closeModal = () => {
		emits('close-modal');
	};
</script>

<style scoped>
	.modal-title {
		color: var(--bs-primary);
	}

	.modal-footer {
		display: flex;
		justify-content: center;
		gap: 10px;
	}

	.modal-sm {
		max-width: 300px;
	}

	.btn-link {
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.12px;
		text-decoration: underline;
		color: var(--bs-primary);
		border: none;
		background: none;
		cursor: pointer;
		padding: 0;
	}

	:deep(.modal-content) {
		background: white !important;
	}

	:deep(.modal) {
		z-index: 1056 !important;
	}

	:deep(.modal-container) {
		min-width: 50vw;
		padding: 25px 30px;
	}

	.btn-guardar {
		box-shadow: 0px 1px 2px 0px rgba(var(--bs-primary-rgb), 0.35) !important;
		font-size: 14px;
		font-weight: 500;
		padding: 10px 20px;
	}
</style>
