<template>
	<teleport to="body">
		<modal-base
			:show="modalActive"
			hide-submit
			hide-cancel
			:title="changeTitle ? $t('titulos.motivosCancelacion') : $t('reserva.reservasCancelar')"
			@on-close="close">
			<template #body>
				<CancelarReservas :grupo="grupo" @motivos-cancelacion="motivosCancelacion" @close="close" />
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase';
	import CancelarReservas from '@/components/modales/CancelarReservas.vue';
	import { ref } from 'vue';

	defineProps({
		grupo: {
			type: Object,
			default: null,
		},
		modalActive: {
			type: Boolean,
			default: null,
		},
	});

	const emit = defineEmits(['close-modal']);
	const changeTitle = ref(false);

	const motivosCancelacion = m => {
		changeTitle.value = m;
	};

	const close = () => emit('close-modal');
</script>

<style lang="css"></style>
