<template>
	<div v-if="!loading">
		<div class="tus-proximas-reservas">
			<div
				class="background py-2"
				:style="{ 'background-image': 'url(' + storeGlobal.valores.img_mis_reservas_fondo + ')' }">
				<div class="banner">
					<div class="principal text-primary">{{ $t('reserva.misReservas') }}</div>

					<div v-if="!isMobile" class="secundario text-primary">
						{{ $t('reserva.proximosViajes') }} {{ grupos.length }}
					</div>
				</div>
			</div>
		</div>
		<div class="seccion-proximas-reservas">
			<div class="contenedor-tarjetas overflow-hidden">
				<div class="row">
					<template v-if="grupos.length > 0">
						<template v-if="isMobile">
							<div class="secundario text-primary">
								{{ $t('reserva.proximosViajes') + ' ' + grupos.length }}
							</div>
							<UsuarioProximasReservasCarousel
								:information-data-list="grupos"
								class="col-12 usuario-proximas-reservas-carousel" />
						</template>
						<template v-else>
							<CardUsuarioReserva v-for="grupo in grupos" :key="grupo.id" class="col-12 col-md-3 pb-4" :grupo="grupo" />
						</template>
					</template>
					<template v-else>
						<NotFoundReservaForm :title="$t('reserva.noReservas')" />
					</template>
				</div>

				<div class="espacio" />
			</div>
		</div>
	</div>
	<div v-else class="min-vh-100 d-flex justify-content-center">
		<Spinner />
	</div>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';

	import { ref, onMounted, onBeforeMount } from 'vue';
	import { proximasExperiencias } from '@/store_pinia/proximasExperiencias';
	import { appGlobalStore } from '@/store_pinia/app';
	import CardUsuarioReserva from '@/components/card/Reserva/CardUsuarioReserva.vue';
	import UsuarioProximasReservasCarousel from '@/components/Carousel/UsuarioProximasReservasCarousel.vue';
	import NotFoundReservaForm from '@/components/form/NotFoundReservaForm';

	const storeGlobal = appGlobalStore();
	const storeProxExp = proximasExperiencias();
	const loading = ref(true);
	const grupos = ref([]);
	const isMobile = ref(window.innerWidth <= 992);

	function handleResize() {
		isMobile.value = window.innerWidth <= 992;
	}

	async function init() {
		await storeProxExp.loadProximasExperiencias();
		loading.value = false;
		grupos.value = storeProxExp.getGrupos;
	}

	onBeforeMount(() => init());

	onMounted(() => {
		window.addEventListener('resize', handleResize);
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';
	.tus-proximas-reservas {
		position: relative;
	}

	.secundario {
		font-size: 30px;
		font-weight: 400;
		padding-bottom: 50px;
	}
	.background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: white;
		z-index: -1;
		min-height: 100vh;
		display: initial;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;

		.banner {
			margin: 0 5rem;
			margin-top: 12rem;

			.principal {
				font-size: 40px;
				font-weight: 700;
				padding-bottom: 30px;
			}
		}
	}
	.seccion-proximas-reservas {
		padding-top: 3rem;
		margin: 0 5rem;
		@media only screen and (max-width: 1000px) {
			margin: 0 2rem;
		}

		.contenedor-tarjetas {
			margin-top: 20rem;
		}
	}
	.espacio {
		margin-bottom: 1rem;
	}

	@include media-breakpoint-down(lg) {
		.seccion-proximas-reservas {
			.contenedor-tarjetas {
				margin-top: 7rem;
			}
		}
		.secundario {
			font-size: 16px;
			font-weight: 400;
			padding-bottom: 10px;
		}
		.background {
			background-image: var(--bs-body-bg) !important;

			.banner {
				margin: 0 2rem;
				margin-top: 7rem;
				.principal {
					font-size: 18px;
					font-weight: 600;
					padding-bottom: 10px;
				}
			}
		}
	}

	.tarjetas {
		margin-bottom: 3rem;
		gap: 1rem;
	}

	.usuario-proximas-reservas-carousel {
		width: 100%;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
</style>
