<template>
	<div class="formulario-presupuesto">
		<div class="thanks">
			<div class="text-center">
				<h1 class="text-primary fw-bolder">!{{ $t('reserva.graciasReserva') }}!</h1>
				<p class="fs-4 text-white">¡{{ $t('home.form.Thanks.subtitle') }}!</p>
				<router-link to="/" class="btn btn-secondary">
					{{ $t('home.key') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		setup() {
			return {};
		},
	};
</script>

<style lang="css" scoped>
	.formulario-presupuesto {
		background-image: url('../../../assets/fotos/est1.webp');
		background-size: cover;
		background-position: 50%;
		height: 100%;
		margin: auto;
		padding: 15rem 2rem 11rem 2rem;
	}
</style>
