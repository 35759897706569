const RECURSOS_DINAMICOS_PATH = '/resources/dyn_img/iconos/';
/**
 * Devuele el path de la imagen alojada en la ruta dinámica
 * @param imgNombre
 * @returns {string}
 */
export function getImagenDinamicaPorNombre(imgNombre) {
	return RECURSOS_DINAMICOS_PATH + imgNombre;
}

/**
 * Devuele el path del icono dependiendo del tipo y subtipo indicado
 * @param tipo
 * @param subtipo
 * @returns {string}
 */
export function getImagenIconoPorTipoYSubtipo(tipo, subtipo = null) {
	let nombre;

	switch (tipo) {
		case 'Actividad':
			if (subtipo == 'aventura') nombre = 'ActividadAventura.webp';
			else if (subtipo == 'ocio') nombre = 'ActividadOcio.webp';
			else if (subtipo == 'evento') nombre = 'ActividadEvento.webp';
			else nombre = 'ActividadRelax.webp';
			break;

		case 'Festival':
			if (subtipo == 'entrada') nombre = 'FestivalEntrada.webp';
			else if (subtipo == 'complemento') nombre = 'FestivalComplemento.webp';
			else nombre = 'FestivalConsumiciones.webp';
			break;

		case 'Alquiler':
			if (subtipo == 'Snowboard') nombre = 'AlquilerSnow.webp';
			else nombre = 'AlquilerSki.webp';
			break;

		case 'Clases':
			if (subtipo == 'Snowboard') nombre = 'ClasesSnow.webp';
			else nombre = 'ClasesSki.webp';
			break;

		case 'Forfait':
			nombre = 'Forfait.webp';
			break;

		case 'Restauracion':
			nombre = 'Restauracion.webp';
			break;

		case 'Seguro':
			if (subtipo == 'cancelacion') nombre = 'SeguroCancelacion.webp';
			else nombre = 'SeguroSalud.webp';
			break;

		case 'Transporte':
			nombre = 'Transporte.webp';
			break;

		case 'Parking':
			nombre = 'parking.jpg';
			break;

		case 'GreenFee':
			nombre = 'greenFee.png';
			break;

		case 'Relax':
			nombre = 'relax.png';
			break;

		case 'Mascota':
			nombre = 'mascota.png';
			break;

		default:
			nombre = 'ClasesSki.webp';
			break;
	}

	return nombre;
}
