<template>
	<!-- Sección cabecera del apres ski -->
	<div class="col-12 d-flex flex-row align-items-center justify-content-around apres-ski-img-fondo" />

	<div class="mx-2 mx-sm-5 apres-skis-catalogo pt-4 pt-sm-5">
		<div class="col-12 d-flex flex-row mb-2">
			<div class="titulo me-3">Los Mejores Après Skis</div>
			<div class="dropdown mb-4">
				<button
					id="dropdownMenuMiembrosGrupo"
					class="btn boton-miembroActual d-flex flex-row justify-content-between align-items-center"
					type="button"
					data-bs-toggle="dropdown"
					aria-expanded="false">
					<span v-if="estacionSeleccionada">
						{{ estacionSeleccionada }}
					</span>
					<span v-else>Estación...</span>

					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						class="bi bi-chevron-down"
						viewBox="0 0 16 16">
						<path
							fill-rule="evenodd"
							d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
					</svg>
				</button>

				<ul class="dropdown-menu" aria-labelledby="dropdownMenuMiembrosGrupo">
					<li>
						<button
							v-for="lugarExperiencia in lugaresExperiencia"
							:key="lugarExperiencia"
							class="dropdown-item"
							type="button"
							@click="estacionSeleccionada = lugarExperiencia">
							{{ lugarExperiencia }}
						</button>
					</li>
				</ul>
			</div>
		</div>

		<div class="col-12 mb-4">Aquí puedes consultar los datos de los mejores après skis</div>

		<!-- carrusel 1 -->
		<template v-for="lugarExperiencia in lugaresExperiencia" :key="lugarExperiencia">
			<base-carousel
				v-if="!estacionSeleccionada || estacionSeleccionada == lugarExperiencia"
				:items-carousel="filterByEstacion(lugarExperiencia)"
				:is-loading="loadingApresSkis"
				:autoplay-time="99999999"
				:max-elements="3"
				style="max-width: 1440px">
				<template #title>
					<div class="d-flex justify-content-between align-items-center">
						<p class="subtitulo">
							{{ lugarExperiencia }}
						</p>
					</div>
				</template>
				<template #itemCarousel="itemCarouselProps">
					<TarjetaHomeMejoresApresSkis :apres-ski="itemCarouselProps.data" />
				</template>
			</base-carousel>
		</template>
	</div>
</template>

<script setup>
	import TarjetaHomeMejoresApresSkis from '@/components/tarjetas/TarjetaHomeMejoresApresSkis.vue';
	import { ref, computed } from 'vue';
	import { apresSkisStore } from '@/store_pinia/apresSkis';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';

	const estacionSeleccionada = ref();
	const apresSkis = ref([]);
	const loadingApresSkis = ref(true);
	const store_pinia_apresSkis = apresSkisStore();
	store_pinia_apresSkis.setApresSkis().then(() => {
		apresSkis.value = store_pinia_apresSkis.apresSkis;
		loadingApresSkis.value = false;
	});

	const lugaresExperiencia = computed(() => {
		let lugaresExperiencia = [];

		apresSkis.value.forEach(element => {
			if (!lugaresExperiencia.includes(element.lugarExperiencia)) {
				lugaresExperiencia.push(element.lugarExperiencia);
			}
		});

		return lugaresExperiencia;
	});

	const filterByEstacion = function (lugarExperiencia) {
		return apresSkis.value.filter(obj => {
			return obj.lugarExperiencia == lugarExperiencia;
		});
	};
</script>

<style lang="css" scoped>
	.apres-skis-catalogo {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 1rem;
	}

	.apres-ski-img-fondo {
		min-height: 50vh;
		background-image: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
			url('~@/assets/fotos/header_catalogo_apres_ski.webp');
		background-size: cover;
		background-position: center;
	}

	@media (max-width: 575px) {
		.apres-ski-img-fondo {
			min-height: 30vh;
		}
	}

	.titulo {
		font-size: 1.6rem;
		font-weight: 700;
	}

	.subtitulo {
		font-size: 1.3rem;
		font-weight: 700;
		margin-bottom: 0.5rem;
	}

	.boton-miembroActual {
		border-radius: var(--bs-border-radius-lg);
		min-width: 10rem;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
	}
</style>
