<template>
	<div>
		<template v-if="viaje.id">
			<div class="card">
				<template v-if="backgroundCards">
					<div class="top-image">
						<img :src="imageCategory(backgroundCards)" class="image" alt="Trip Image" />
					</div>
				</template>
				<div class="card">
					<div class="card-body">
						<div class="row w-100 mb-3 m-0">
							<div class="col-8 p-0">
								<div class="fs-4 text-primary mb-2 fw-bold">{{ viaje.nombre }}</div>
								<div class="text-muted">
									<img src="@/assets/icons/ubi.svg" alt="Location" class="icono-ubicacion" />
									{{ viaje.lugarExperiencia?.nombre }}
								</div>
								<div class="text-muted mb-2">
									Fechas: {{ dateMonthYearDDmmYYYY(viaje.fechaInicio) }}
									<template v-if="viaje.fechaInicio !== viaje.fechaFin">
										- {{ dateMonthYearDDmmYYYY(viaje.fechaFin) }}
									</template>
								</div>
							</div>
							<div class="col-4 text-end p-0">
								<div v-if="qrVisible && showQrButton" class="d-flex flex-column align-items-end">
									<div class="qr-content pointer" @click="showModalQr">
										<QrIcon />
										<div class="text-primary mt-2 text-center">{{ $t('botones.verBono') }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body___content">
							<template v-for="(reserva, index) in resumenReservas" :key="reserva.id">
								<div :class="{ 'opacity-50': reserva.estado === ESTADOS_RESERVA.CANCELADA }">
									<div class="px-3 pt-3">
										<div
											class="text-primary text-bold row"
											:class="{ pointer: !disableCollapse }"
											@click="toggleCollapse(index)">
											<div class="col" :class="{ 'text-danger': reserva.estado === ESTADOS_RESERVA.CANCELADA }">
												<span v-if="typeof reserva.usuario != 'object'">
													{{ reserva.usuario }}
												</span>
												<template v-else>
													<span v-if="reserva.usuario.nombre.length === 0" style="margin-right: 4px">
														{{ $t('input.field.nombre') }}
													</span>
													<span v-if="reserva.usuario.apellido.length === 0">
														{{ $t('input.field.apellido') }}
													</span>
												</template>
												<span>
													{{ reserva.usuario.nombre }}
													{{ reserva.usuario.apellido }}
												</span>
												<template v-if="reserva.estado === ESTADOS_RESERVA.CANCELADA">
													<span class="badge text-bg-danger ms-3"> {{ reserva.estado }}</span>
													<button type="button" class="btn btn-link" @click="recuperarReserva">
														{{ $t('reserva.recuperarReserva') }}
													</button>
												</template>
											</div>
											<div class="col-3 text-end">
												<button class="btn bg-transparent border-0" :class="{ 'd-none': disableCollapse }">
													<i
														class="fa-solid fa-chevron-up"
														:class="isCollapsed[index] ? 'icon-active' : 'icon-inactive'" />
												</button>
											</div>
										</div>
									</div>
									<div>
										<transition name="collapse">
											<div v-show="!isCollapsed[index]" class="collapse-content">
												<hr class="line-divider mx-3 mt-0" />
												<div class="pb-1 m-2 row text-description">
													<div class="col-8 fw-bold text-secondary pb-2">
														{{ $t('input.field.paqueteBasico') }}
													</div>
													<div class="col-4 text-end text-secondary fw-bold pb-2">
														<span> {{ reserva.precioBasico || storeHabitacion.calculaPrecioBaseGrupo }} €</span>
													</div>
													<div class="col-12 p-0">
														<template v-if="alojamiento">
															<div class="text-primary text-description pl-1 pb-1">
																<div>
																	{{ alojamiento.nombre }}
																</div>
																<div v-if="alojamiento.estrellas">
																	<template v-for="estrella in alojamiento.estrellas" :key="estrella">
																		<i class="fa-regular fa-star"></i>
																	</template>
																</div>
																<div>
																	{{ dateMonthNumbers(viaje.fechaInicio) }} - {{ dateMonthNumbers(viaje.fechaFin) }}
																</div>
																<template v-if="alojamiento.hasHabitaciones">
																	<span v-if="alojamiento.habitacion.tipo">
																		{{ alojamiento.habitacion.tipo.nombre }}
																	</span>
																	{{ alojamiento.habitacion.numPlazas }}
																	{{ $t('general.personas.plural.key') }}
																	<div v-if="regimenTraducido" class="text-primary mt-1">
																		{{ regimenTraducido }}
																	</div>
																</template>
															</div>
														</template>
														<template v-for="inclu in incluyeFiltrado" :key="inclu.nombre">
															<div class="text-primary pl-1 pb-1">{{ inclu.nombre }} {{ inclu.descripcion }}</div>
														</template>
														<template v-for="servicio in reserva.servicios" :key="servicio.id">
															<template v-if="servicio.paqueteBasico">
																<div class="mt-2 text-primary pl-1">
																	{{ servicio.nombre }} ({{ dateMonthNumbers(servicio.fechaInicio) }} -
																	{{ dateMonthNumbers(servicio.fechaFin) }})
																</div>
															</template>
														</template>
													</div>
													<template
														v-if="
															reserva.servicios.some(
																servicio =>
																	(servicio.nulo && servicio.precio !== 0) ||
																	(!servicio.nulo && !servicio.paqueteBasico)
															)
														">
														<div class="col-12 fw-bold text-secondary py-2">
															{{ $t('reserva.extras') }}
														</div>
														<template
															v-for="servicio in reserva.servicios.filter(s => !s.nulo && !s.paqueteBasico)"
															:key="servicio.id">
															<div class="col-9 text-primary pl-1 mb-2">
																<!-- COMENTADO a peticion -->
																<!-- {{ servicio.numDias }}
																<span v-if="servicio.numDias === 1"> {{ $t('general.dia') }}</span>
																<span v-else> {{ $t('general.dias') }}</span> -->
																{{ servicio.nombre }} ({{ dateMonthNumbers(servicio.fechaInicio) }} -
																{{ dateMonthNumbers(servicio.fechaFin) }})
															</div>
															<div v-if="servicio.precio" class="col-3 text-secondary text-end fw-bold">
																{{ servicio.precio }} €
															</div>
														</template>
														<template
															v-for="servicio in reserva.servicios.filter(s => s.nulo && s.precio !== 0)"
															:key="servicio.id">
															<div class="col-9 text-primary pl-1 mb-2">
																{{ servicio.nombre }} ({{ dateMonthNumbers(servicio.fechaInicio) }} -
																{{ dateMonthNumbers(servicio.fechaFin) }})
															</div>
															<div v-if="servicio.precio" class="col-3 text-secondary text-end fw-bold">
																{{ servicio.precio }} €
															</div>
														</template>
													</template>
													<template
														v-if="
															(reserva.complementos && reserva.complementos.length > 0) ||
															(reserva.gastos && reserva.gastos.length > 0)
														">
														<div class="col-12 fw-bold text-secondary py-2">{{ $t('reserva.suplementos.title') }}</div>
														<template v-for="complemento in reserva.complementos" :key="complemento.codigo">
															<div class="col-9 text-primary pl-1 mb-2">
																{{ $t(`reserva.suplementos.${complemento.codigo}`) }}
															</div>
															<div v-if="complemento.importe" class="col-3 text-secondary text-end fw-bold">
																{{ complemento.importe }} €
															</div>
														</template>
														<template v-for="gasto in reserva.gastos" :key="gasto.codigo">
															<div class="col-9 text-primary pl-1 mb-2">
																{{ $t(`reserva.gastos.${gasto.codigo}`) }}
															</div>
															<div v-if="gasto.importe" class="col-3 text-secondary text-end fw-bold">
																{{ gasto.importe }} €
															</div>
														</template>
													</template>
												</div>
												<!-- TOTALES -->
												<hr class="line-divider mx-3 mt-0" />
												<div class="pb-3 mx-2 row text-description">
													<div class="col-8 fw-bold text-primary pb-2">
														{{ $t('subtitulo.total').toUpperCase() }}
													</div>
													<div class="col-4 text-end text-secondary fw-bold pb-2">
														{{ Number(reserva.precioBase.toFixed(2)) }}€
													</div>
													<template v-if="storeHabitacion.getCodigoDescuento">
														<div class="col-12 fw-bold text-primary pb-1">{{ $t('reserva.descuento') }}</div>
														<div class="col-8 px-4 pb-2">
															{{ storeHabitacion.getCodigoDescuento.descripcion }}
															<span class="btn-link" @click="limpiarCodigo">{{ $t('actions.delete') }}</span>
														</div>
														<div class="col-4 text-end text-secondary fw-bold">
															<span> -{{ reserva.descuento }} € </span>
														</div>
													</template>
													<template v-for="tasaExterna in reserva.tasasExternasCalculadas" :key="tasaExterna">
														<div class="col-8 fw-bold text-primary">
															{{ $t(`reserva.tasas.externas.${tasaExterna.codigo}`) }}
														</div>
														<div class="col-4 text-end text-secondary fw-bold">{{ tasaExterna.importe }}€</div>
													</template>
													<template v-for="tasaInterna in reserva.tasasInternasCalculadas" :key="tasaInterna">
														<div class="col-8 fw-bold text-primary">
															{{ $t(`reserva.tasas.internas.${tasaInterna.codigo}`) }}
														</div>
														<div class="col-4 text-end text-secondary fw-bold">
															{{ tasaInterna.importe.toFixed(2) }}€
														</div>
													</template>
												</div>
											</div>
										</transition>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="card-footer text-bold bg-primary text-secondary text-total" style="padding: 13px 18px">
						<div class="row">
							<div class="col-7">
								{{ $t('subtitulo.importeTotal') }}
							</div>
							<div class="col text-end">{{ calculaPrecioTotalReservas(resumenReservas).toFixed(2) }} €</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { dateMonthNumbers, dateMonthYearDDmmYYYY } from '@/helpers/dateUtiles';
	import { calculaPrecioTotalReservas } from '@/helpers/viajesGrupos';
	import { useRoute } from 'vue-router';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { appGlobalStore } from '@/store_pinia/app';
	import viajesGrupo from '@/store_pinia/viajes/viajesGrupo';
	import QrIcon from '@/components/Icons/QrIcon.vue';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import { calculateTotalPendiente } from '@/services/utils/GrupoUtils';

	const { t: $t } = useI18n();

	const props = defineProps({
		precioBase: {
			type: Number || String,
			default: null,
			deprecated: true,
		},
		codigoDescuentoData: {
			type: Object,
			required: false,
			default: null,
			deprecated: true,
		},
		disableCollapse: {
			type: Boolean,
			required: false,
			default: false,
		},
		qrVisible: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const emit = defineEmits(['limpiarCodigoDescuento', 'showModalQr', 'recuperarReserva']);

	const storeHabitacion = informacionHabitacion();
	const buscador = viajesGrupo();
	const storeGlobal = appGlobalStore();
	const isCollapsed = ref([]);

	const srcImages = [
		storeGlobal.valores.img_cabeceracard_universitarios,
		storeGlobal.valores.img_cabeceracard_familias,
		storeGlobal.valores.img_cabeceracard_trabajadores,
		storeGlobal.valores.img_cabeceracard_colegios,
		storeGlobal.valores.img_cabeceracard_premium,
		storeGlobal.valores.img_cabeceracard_premium,
		storeGlobal.valores.img_cabeceracard_familias,
		storeGlobal.valores.img_cabeceracard_trabajadores,
		storeGlobal.valores.img_cabeceracard_universitarios,
	];

	const viaje = computed(() => storeHabitacion.getViaje);
	const alojamiento = computed(() => storeHabitacion.getAlojamiento);
	const resumenReservas = computed(() => storeHabitacion.getResumenReservas);

	const totalPending = computed(() => calculateTotalPendiente(viaje.value, resumenReservas.value));

	const showQrButton = computed(() => {
		if (!resumenReservas.value || resumenReservas.value.length === 0) {
			return false;
		}
		const isNotCancelled = resumenReservas.value.some(({ estado }) => estado !== ESTADOS_RESERVA.CANCELADA);
		const hasValidService = resumenReservas.value.some(reserva => {
			return reserva.servicios && reserva.servicios.some(({ codigoValidacion, nulo }) => codigoValidacion && !nulo);
		});
		return isNotCancelled && hasValidService && totalPending.value <= 0;
	});

	const incluyeFiltrado = computed(() => {
		if (viaje.value.incluyes && viaje.value.incluyes.length > 0) {
			return viaje.value.incluyes
				.filter(({ nombre }) => nombre.toLowerCase() !== 'alojamiento')
				.filter(({ isIconoViaje }) => isIconoViaje);
		}
		return [];
	});

	const regimenTraducido = computed(() => {
		const regimen = alojamiento?.value.habitacion?.regimen;
		return regimen ? $t(`reserva.tipo.${regimen}`) : '';
	});

	const backgroundCards = computed(() => {
		if (!buscador.filtrosViajes && !buscador.filtrosViajes.tiposGrupo) {
			return undefined;
		} else {
			return buscador.filtrosViajes.tiposGrupo.map((tipoViaje, index) => {
				return {
					currentGroup: tipoViaje.nombre,
					src: srcImages[index],
					alt: 'Background viaje' + tipoViaje.nombre,
					title: 'Background viaje' + tipoViaje.nombre,
				};
			});
		}
	});

	function toggleCollapse(index) {
		if (!props.disableCollapse) {
			isCollapsed.value[index] = !isCollapsed.value[index];
		}
	}

	function showModalQr() {
		emit('showModalQr');
	}

	function recuperarReserva() {
		emit('recuperarReserva');
	}

	const lastScrollPosition = ref(0);

	function onScroll() {
		if (!props.disableCollapse) {
			const documentHeight = document.documentElement.scrollHeight;
			if (documentHeight > 2000) {
				const scrollY = window.scrollY;
				const windowHeight = window.innerHeight + documentHeight * 0.2;
				const isScrollingDown = scrollY > lastScrollPosition.value;
				const scrollDifference = windowHeight - scrollY;
				if (!isScrollingDown && scrollDifference > 200 && scrollDifference < 500) {
					if (isCollapsed.value[0]) {
						isCollapsed.value.fill(false);
					}
				}
				lastScrollPosition.value = scrollY;
			}
		}
	}

	function limpiarCodigo() {
		emit('limpiarCodigoDescuento');
	}

	function imageCategory(listImages) {
		if (viaje.value.imagenExperiencia) {
			return viaje.value.imagenExperiencia;
		}
		const item = listImages.find(
			({ currentGroup }) => currentGroup == useRoute().query.grupo || currentGroup == viaje.value.tipos[0].nombre
		);
		return item ? item.src : '';
	}

	onMounted(() => {
		if (!props.disableCollapse) {
			isCollapsed.value = resumenReservas.value.map(() => false);
			window.addEventListener('scroll', onScroll);
		}
	});

	onUnmounted(() => {
		if (!props.disableCollapse) {
			window.removeEventListener('scroll', onScroll);
		}
	});
</script>
<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.card-image {
		width: 100%;
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0px 1px 2px 1px rgba(33, 36, 68, 0.25), 0px 0px 10px 0px rgba(33, 36, 68, 0.25);
		padding-bottom: 0.2rem;

		&__footer {
			font-size: 0.8em;
		}

		.card-image__header {
			position: relative;

			&__background {
				overflow: hidden;
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;

				img {
					width: 100%;
					height: 100px;
					opacity: 0.5;
					object-fit: cover;
				}
			}

			&__share {
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}

		.card-image__body {
			margin-top: -1rem;
		}
	}

	.card {
		border-radius: var(--bs-border-radius-xl) !important;
		background-color: #f9f7f8 !important;
		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) !important;
		border: 0px;

		.card-body {
			background-color: #f9f7f8;
			z-index: 1000;
			padding: 21px 21px 0 21px;
			border-radius: var(--bs-border-radius-xl) !important;

			&___content {
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0 !important;
				background-color: white;
			}

			.title {
				font-size: 22px;
				font-weight: 700;
			}

			.sub-title {
				color: #515151;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.card-footer {
			border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
			border-top: 0px;

			.rounded-circle {
				width: 30px;
				height: 30px;
				padding: 5px;
				margin-left: 12px;
			}
		}
	}

	.collapse-content {
		font-size: 15px;
		font-weight: 400;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.text-bold {
		font-size: 15px;
		font-weight: 700 !important;
	}

	.btn-link {
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.12px;
		cursor: pointer;
	}

	.text-description {
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.12px;
	}

	.text-total {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.12px;
	}

	.pl-1 {
		padding-left: 20px;
	}

	.top-image {
		@extend .position-relative;
		margin-bottom: -30px;
		z-index: 1000;

		.image {
			@extend .card-img-top, .d-block, .rounded-top-4, .object-fit-cover;
			height: 150px;
		}
	}

	.collapse-enter-active,
	.collapse-leave-active {
		transition: height 3s ease;
	}

	.collapse-enter-from,
	.collapse-leave-to {
		height: 0;
		overflow: hidden;
	}

	.qr-content {
		background-color: #fff;
		filter: drop-shadow(0px 1px 2px rgba(33, 36, 68, 0.35));
		border-radius: var(--bs-border-radius-xl);
		padding: 3px 8px;
	}

	.icono-ubicacion {
		width: 20px;
		height: 20px;
	}

	:deep(.card, .top-image, .image, .sticky-card, .card-body, ) {
		border-radius: var(--bs-border-radius-xl) !important;
	}
</style>
