<template>
	<div class="mb-4">
		<div v-if="!loading">
			<template v-if="informacionAlojamiento.imagenPrincipal && informacionAlojamiento.imagenPrincipal.url">
				<div
					class="blog_banner d-flex justify-content-center align-content-center pb-5"
					:style="{
						backgroundImage: 'url(' + informacionAlojamiento.imagenPrincipal.url + ')',
					}">
					<template v-if="fromBuscador && hotelSeleccionadoBuscador != null">
						<BuscadorParaTi />
					</template>
				</div>

				<!-- Carrusel cabecera alojamiento -->
				<div v-if="informacionAlojamiento.imagenesSecundarias.length > 0" class="carousel-imagenes">
					<div>
						<CarouselAlojamiento :lista-fotos="informacionAlojamiento.imagenesSecundarias.map(img => img.url)" />
					</div>
				</div>
			</template>
			<template v-else>
				<div class="blog_banner">
					<!-- <BuscadorGrupo /> -->
					<div v-if="informacionAlojamiento.imagenesSecundarias.length > 0" class="carousel-imagenes">
						<div>
							<CarouselAlojamiento :lista-fotos="informacionAlojamiento.imagenesSecundarias.map(img => img.url)" />
						</div>
					</div>
				</div>
			</template>
			<div>
				<div class="alojamiento-container px-3 mt-5 row">
					<div class="d-flex justify-content-between align-items-center flex-wrap">
						<div class="d-flex flex-column flex-md-row justify-content-between align-items-start w-100">
							<h1 class="fw-bolder mb-0 h-100">{{ informacionAlojamiento.nombre }}</h1>
							<button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#modalFotos">
								{{ $t('alojamiento.verFoto') }}
							</button>
							<ModalFotos
								:informacion-alojamiento="informacionAlojamiento"
								:nombre-alojamiento="informacionAlojamiento.nombre" />
						</div>
					</div>
					<span class="text-muted mt-2 p-0">
						<button class="btn btn-link" data-bs-toggle="modal" data-bs-target="#mapaModalAlojamiento">
							<i class="fa-solid fa-location-pin" />
							{{ informacionAlojamiento.ubicacion }}
						</button>
					</span>
					<div style="display: inline-block" class="mt-0 mb-3">
						<template v-for="estrellas in informacionAlojamiento.estrellas" :key="estrellas">
							<i class="fa-regular fa-star" />
						</template>
					</div>

					<!-- Botones acceso rápido -->
					<div class="mb-3 d-flex align-items-center botones-container">
						<button class="ms-0 text-primary boton boton-blanco" @click="scrollToElement('servicios')">
							{{ $t('alojamiento.servicios') }}
						</button>
						<button v-if="fromBuscador" class="text-primary boton boton-blanco" @click="scrollToElement('ofertas')">
							{{ $t('alojamiento.ofertas') }}
						</button>
						<button class="text-primary boton boton-blanco" @click="scrollToElement('preguntas')">
							{{ $t('alojamiento.preguntasFrecuentes') }}
						</button>
					</div>

					<div class="row text-container mt-3">
						<div class="col-6 ps-3 pe-4 left">
							<h6 class="fs-5 fw-semibold mb-3">
								{{ $t('alojamiento.horariosCondiciones') }}
							</h6>

							<div class="row d-flex flex-column flex-md-row my-3 card-check">
								<div class="col-12 col-sm-6">
									<div class="row">
										<div class="col-3">
											<i class="fa-solid fa-door-open fa-2x icon-checkin" />
										</div>
										<div class="col-9">
											<p class="m-0 fw-bold">
												{{ $t('alojamiento.checkIn') }}
											</p>
											<span>
												{{ informacionAlojamiento.checkIn }}
											</span>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div class="row">
										<div class="col-3">
											<i class="fa-solid fa-door-open fa-2x" />
										</div>
										<div class="col-9">
											<p class="m-0 fw-bold">
												{{ $t('alojamiento.checkOut') }}
											</p>
											<span>
												{{ informacionAlojamiento.checkOut }}
											</span>
										</div>
									</div>
								</div>
							</div>

							<template
								v-if="
									informacionAlojamiento.accesosPistas.length > 0 || informacionAlojamiento.puntosinteres.length > 0
								">
								<h4 class="fs-4and5 fw-bold mt-4 mb-3">
									{{ $t('alojamiento.distancias') }}
								</h4>
								<template v-if="informacionAlojamiento.accesosPistas.length > 0">
									<h6 class="fs-7 fw-bold">
										{{ $t('alojamiento.accesoPistas') }}
									</h6>

									<p
										v-for="(acceso, index) in informacionAlojamiento.accesosPistas"
										:key="index"
										class="text-muted fs-8">
										<img class="icono-distancia-estacion" src="@/assets/icons/icono-telesilla.webp" />
										{{ acceso.distancia >= 1 ? acceso.distancia + ' km' : acceso.distancia * 1000 + ' m' }}
										{{ acceso.nombre }}
										<br />
										<span class="ms-4">({{ titleCase(acceso.lugarExperiencia) }})</span>
									</p>
								</template>

								<template v-if="informacionAlojamiento.puntosinteres.length > 0">
									<h6 class="fs-7 fw-bold">
										{{ $t('alojamiento.puntoInteres') }}
									</h6>

									<p
										v-for="(punto, index) in informacionAlojamiento.puntosinteres"
										:key="index"
										class="text-muted fs-8">
										<img class="icono-distancia-estacion" src="@/assets/icons/icono-punto-interes.webp" />
										{{ punto.distancia }} {{ punto.nombre }}
									</p>
								</template>
							</template>
						</div>
						<div class="col-6 ps-4 right">
							<h5 class="fs-5 fw-semibold">
								{{ $t('alojamiento.descripcionAlojamiento') }}
							</h5>
							<p class="my-3 text-primary" :class="mostrarMas ? '' : 'informacion'">
								{{ informacionAlojamiento.info }}
							</p>

							<button v-if="!mostrarMas" type="button" class="btn btn-link" @click="mostrarMas = !mostrarMas">
								{{ $t('general.mostrarMas') }}
							</button>
							<button v-else type="button" class="btn btn-link" @click="mostrarMas = !mostrarMas">
								{{ $t('general.mostrarMenos') }}
							</button>
						</div>
					</div>

					<div v-if="fromBuscador && hotelSeleccionadoBuscador != null" id="ofertas">
						<h6 class="fs-4and5 fw-bold my-3">
							{{ $t('alojamiento.regimenDisponibles') }} |
							<span class="fw-normal">
								{{ formatDateWithWeekDay(fechasAlojamiento[0]) }}
								-
								{{ formatDateWithWeekDay(fechasAlojamiento[1]) }}
							</span>
						</h6>

						<!-- Opciones pension -->
						<div class="d-flex gap-2 me-3 mt-4 flex-wrap">
							<div
								v-for="(lugaresExperiencia, pension) in hotelSeleccionadoBuscador.pensiones"
								:key="pension"
								class="boton-cuadro-azul"
								:class="hotelSeleccionadoBuscador.currentPension == pension ? 'boton-cuadro-azul-selected' : ''"
								@click="updateOpcionesPensionActual(pension)">
								{{ opcionesPension[pension] }}
							</div>
						</div>

						<!-- Cuadro pension actual lugaresExperiencia -->
						<template v-for="(lugaresExperiencia, pension) in hotelSeleccionadoBuscador.pensiones" :key="pension">
							<!-- Pension actual -->
							<template v-if="hotelSeleccionadoBuscador.currentPension == pension">
								<!-- Cuadro lugarExperiencia elegida -->
								<template v-for="(opciones, lugarExperiencia) in lugaresExperiencia" :key="lugarExperiencia">
									<div
										v-if="estacionSeleccionadaBuscador == lugarExperiencia"
										class="d-flex flex-column cuadro-forfaits my-3 p-2 p-md-4">
										<!-- Info lugarExperiencia -->
										<div class="d-flex flex-column flex-lg-row w-100 justify-content-between mb-3">
											<!-- Titulo y distancia -->
											<div class="d-flex gap-2">
												<div class="row">
													<div>
														<img
															class="img-lugarExperiencia"
															:src="
																estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																	? estacionesData[lugarExperiencia].imagen
																	: ''
															"
															alt="" />
													</div>

													<div class="texto-estacion">
														<p class="mb-0 fw-bold">
															{{
																estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																	? estacionesData[lugarExperiencia].nombre
																	: ''
															}}
														</p>
														<div
															v-if="hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia]"
															class="d-flex align-items-start gap-1 ms-0">
															<img class="icono-distancia-estacion" src="@/assets/icons/icono-telesilla.webp" />
															<span class="texto-distancia-estacion">
																{{ $t('general.a') }}
																{{
																	hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM >= 1
																		? hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM + ' km'
																		: hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM * 1000 +
																		  ' m'
																}}
																{{ $t('alojamiento.deEstacionEsqui') }}({{
																	hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].nombreAccesoPista
																}})
															</span>
														</div>
													</div>
												</div>
											</div>

											<!-- Texto estacion -->
											<div class="info-estacion">
												{{
													estacionesData != null && estacionesData[lugarExperiencia] !== undefined
														? estacionesData[lugarExperiencia].texto
														: ''
												}}
											</div>

											<template v-if="desplegarEstaciones[lugarExperiencia] !== undefined">
												<!-- Icono up y down -->
												<i
													v-if="desplegarEstaciones[lugarExperiencia]"
													class="cursor-pointer ms-5 me-3 fs-3 fa-solid fa-angle-up d-flex justify-content-end"
													@click="desplegarEstaciones[lugarExperiencia] = !desplegarEstaciones[lugarExperiencia]" />
												<i
													v-else
													class="cursor-pointer ms-5 me-3 fs-3 fa-solid fa-angle-down d-flex justify-content-end"
													@click="desplegarEstaciones[lugarExperiencia] = !desplegarEstaciones[lugarExperiencia]" />
											</template>
										</div>
										<template
											v-if="
												desplegarEstaciones[lugarExperiencia] !== undefined &&
												desplegarEstaciones[lugarExperiencia] &&
												numOpcionesMostrar[lugarExperiencia] !== undefined
											">
											<!-- Cuadro opcion -->
											<div
												v-for="(opcion, indOpcion) in opciones.slice(0, numOpcionesMostrar[lugarExperiencia])"
												:key="indOpcion"
												class="d-flex flex-column flex-md-row cuadro-opcion my-2 justify-content-between p-4">
												<div class="d-flex flex-column gap-4 informacion-habitaciones">
													<template v-for="(habitacion, indHab) in opcion.combinacion" :key="indHab">
														<p class="fw-bold mb-0">
															{{ $t('general.habitacion') }}
															{{ titleCase(habitacion.texto) }}
														</p>
														<div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
															<div class="ms-4 d-flex w-100">
																<div v-for="i in habitacion.precios.personas.numAdultos" :key="i" class="personas">
																	<img src="~@/assets/icons/personita.svg" alt="" />
																</div>
																<div
																	v-for="i in habitacion.precios.personas.numNinios"
																	:key="i"
																	class="personas d-flex">
																	<img class="personitas" src="~@/assets/icons/personita.svg" alt="" />
																</div>
																{{ formatMiembrosHabitacion(habitacion.precios.personas) }}
															</div>
															<div class="d-flex flex-column text-start text-sm-end w-100">
																<p v-if="habitacion.precios.personas.numAdultos > 0" class="m-0">
																	{{ $t('general.personas.mayus.adulto') }}:
																	<span class="fw-bold"> {{ habitacion.precios.personas.precioAdulto }}€ </span>
																</p>
																<p v-for="(n, i) in habitacion.precios.personas.ninios" :key="i" class="m-0">
																	{{ $t('general.personas.mayus.nino') }}
																	{{ n.edad }}
																	{{ $t('general.anios') }}:
																	<span class="fw-bold">{{ n.precio }}€</span>
																</p>
															</div>
														</div>
													</template>
												</div>

												<div class="d-flex flex-column align-items-end justify-content-between">
													<!-- Tipo Cancelación -->
													<div
														v-if="opcion.tipoCancelacion == 'TOTAL'"
														class="texto-cancelacion-total fw-bold d-flex justify-content-end align-items-center gap-1 mt-1">
														<i class="fa-solid fa-circle-info" />
														{{ $t('alojamiento.cancelacionGratis') }}
													</div>
													<div
														v-else-if="opcion.tipoCancelacion == 'PARCIAL'"
														class="texto-cancelacion-parcial fw-bold d-flex justify-content-end align-items-center gap-1 mt-1">
														<i class="fa-solid fa-circle-info" />
														{{ $t('alojamiento.parcialmenteReembolsable') }}
													</div>
													<div v-else />

													<!-- Precio -->
													<div class="d-flex flex-column justify-content-end gap-2 alignt-items-center">
														<p class="d-flex align-items-center gap-2 texto-desde-precio m-0 justify-content-end">
															{{ $t('subtitulo.total') }}:
															<span class="fs-5 fw-bold"> {{ opcion.precioTotalReserva }}€ </span>
														</p>
														<div class="w-100 text-end">
															<button
																class="boton boton-naranja fw-bold ms-0"
																@click="saveCombinacion(opcion, lugarExperiencia)">
																{{ $t('general.reservar') }}
															</button>
														</div>
													</div>
												</div>
											</div>

											<!-- Botones mostrar mas y menos -->
											<div class="d-flex justify-content-end align-content-center mt-2 mb-1 me-2 gap-3">
												<div
													v-if="
														numOpcionesMostrar[lugarExperiencia] !== undefined &&
														numOpcionesMostrar[lugarExperiencia] > 2
													"
													class="boton-mostrar"
													@click="mostrarOpciones(lugarExperiencia, false)">
													{{ $t('general.mostrarMenos') }}
												</div>
												<div
													v-if="
														numOpcionesMostrar[lugarExperiencia] !== undefined &&
														numOpcionesMostrar[lugarExperiencia] < opciones.length
													"
													class="boton-mostrar"
													@click="mostrarOpciones(lugarExperiencia, true)">
													{{ $t('general.mostrarMas') }}
												</div>
											</div>
										</template>
									</div>
								</template>

								<!-- Titulo resto estaciones -->
								<h6
									v-if="
										Object.keys(lugaresExperiencia).filter(e => {
											return e != estacionSeleccionadaBuscador;
										}).length > 0
									"
									class="fs-4and5 fw-bold my-4 mb-3">
									{{ $t('alojamiento.otrasOpcionesViaje') }}
								</h6>
								<!-- Cuadro resto lugaresExperiencia -->
								<template v-for="(opciones, lugarExperiencia) in lugaresExperiencia" :key="lugarExperiencia">
									<div
										v-if="estacionSeleccionadaBuscador != lugarExperiencia"
										class="d-flex flex-column cuadro-forfaits my-3 p-2 p-md-4">
										<!-- Info lugarExperiencia -->
										<div class="d-flex flex-column flex-md-row w-100 justify-content-between mb-3">
											<!-- Titulo y distancia -->
											<div class="d-flex gap-2">
												<div>
													<img
														class="img-estacion"
														:src="
															estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																? estacionesData[lugarExperiencia].imagen
																: ''
														"
														alt="" />
												</div>

												<div class="texto-estacion">
													<p class="mb-0 fw-bold">
														{{
															estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																? estacionesData[lugarExperiencia].nombre
																: ''
														}}
													</p>
													<div
														v-if="hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia]"
														class="d-flex align-items-start gap-1 ms-0">
														<img class="icono-distancia-estacion" src="@/assets/icons/icono-telesilla.webp" />
														<span class="texto-distancia-estacion">
															a
															{{
																hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM >= 1
																	? hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM + ' km'
																	: hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].distanciaKM * 1000 +
																	  ' m'
															}}
															{{ $t('alojamiento.deEstacionEsqui') }}({{
																hotelSeleccionadoBuscador.distanciasPistas[lugarExperiencia].nombreAccesoPista
															}})
														</span>
													</div>
												</div>
											</div>

											<!-- Texto estacion -->
											<div class="info-estacion">
												{{
													estacionesData != null && estacionesData[lugarExperiencia] !== undefined
														? estacionesData[lugarExperiencia].texto
														: ''
												}}
											</div>
											<template v-if="desplegarEstaciones[lugarExperiencia] !== undefined">
												<!-- Icono up y down -->
												<i
													v-if="desplegarEstaciones[lugarExperiencia]"
													class="cursor-pointer ms-5 me-3 fs-3 fa-solid fa-angle-up d-flex justify-content-end"
													@click="desplegarEstaciones[lugarExperiencia] = !desplegarEstaciones[lugarExperiencia]" />
												<i
													v-else
													class="cursor-pointer ms-5 me-3 fs-3 fa-solid fa-angle-down d-flex justify-content-end"
													@click="desplegarEstaciones[lugarExperiencia] = !desplegarEstaciones[lugarExperiencia]" />
											</template>
										</div>

										<template
											v-if="
												desplegarEstaciones[lugarExperiencia] !== undefined &&
												desplegarEstaciones[lugarExperiencia] &&
												numOpcionesMostrar[lugarExperiencia] !== undefined
											">
											<!-- Cuadro opcion -->
											<div
												v-for="(opcion, indOpcion) in opciones.slice(0, numOpcionesMostrar[lugarExperiencia])"
												:key="indOpcion"
												class="d-flex flex-column flex-md-row cuadro-opcion my-2 justify-content-between p-4">
												<div class="d-flex flex-column gap-4 informacion-habitaciones">
													<template v-for="(habitacion, indHab) in opcion.combinacion" :key="indHab">
														<p class="fw-bold mb-0">
															{{ $t('general.habitacion') }}
															{{ titleCase(habitacion.texto) }}
														</p>
														<div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
															<div class="ms-4 d-flex w-100">
																<div v-for="i in habitacion.precios.personas.numAdultos" :key="i" class="personas">
																	<img src="~@/assets/icons/personita.svg" alt="" />
																</div>
																<div
																	v-for="i in habitacion.precios.personas.numNinios"
																	:key="i"
																	class="personas d-flex">
																	<img class="personitas" src="~@/assets/icons/personita.svg" alt="" />
																</div>
																{{ formatMiembrosHabitacion(habitacion.precios.personas) }}
															</div>
															<div class="d-flex flex-column text-start text-md-end w-100">
																<p v-if="habitacion.precios.personas.numAdultos > 0" class="m-0">
																	{{ $t('general.personas.mayus.adulto') }}:
																	<span class="fw-bold">
																		{{
																			habitacion.precios.personas.precioAdulto % 1 != 0
																				? habitacion.precios.personas.precioAdulto.toFixed(2)
																				: habitacion.precios.personas.precioAdulto
																		}}€
																	</span>
																</p>
																<p v-for="(n, i) in habitacion.precios.personas.ninios" :key="i" class="m-0">
																	{{ $t('general.personas.mayus.nino') }}
																	{{ n.edad }}
																	{{ $t('general.anios') }}:
																	<span class="fw-bold">
																		{{ n.precio % 1 != 0 ? n.precio.toFixed(2) : n.precio }}€
																	</span>
																</p>
															</div>
														</div>
													</template>
												</div>

												<div class="d-flex flex-column align-items-end justify-content-between">
													<!-- Tipo Cancelación -->
													<div
														v-if="opcion.tipoCancelacion == 'TOTAL'"
														class="texto-cancelacion-total fw-bold d-flex justify-content-end align-items-center gap-1 mt-1">
														<i class="fa-solid fa-circle-info" />
														{{ $t('alojamiento.cancelacionGratis') }}
													</div>
													<div
														v-else-if="opcion.tipoCancelacion == 'PARCIAL'"
														class="texto-cancelacion-parcial fw-bold d-flex justify-content-end align-items-center gap-1 mt-1">
														<i class="fa-solid fa-circle-info" />
														{{ $t('alojamiento.parcialmenteReembolsable') }}
													</div>
													<div v-else />

													<!-- Precio -->
													<div class="d-flex flex-column justify-content-end gap-2 alignt-items-center">
														<p class="d-flex align-items-center gap-2 texto-desde-precio m-0 justify-content-end">
															{{ $t('subtitulo.total') }}:
															<span class="fs-5 fw-bold"> {{ opcion.precioTotalReserva }}€ </span>
														</p>
														<div class="w-100 text-end">
															<button
																class="boton boton-naranja fw-bold ms-0"
																@click="saveCombinacion(opcion, lugarExperiencia)">
																{{ $t('general.reservar') }}
															</button>
														</div>
													</div>
												</div>
											</div>

											<!-- Botones mostrar mas y menos -->
											<div class="d-flex justify-content-end align-content-center mt-2 mb-1 me-2 gap-3">
												<div
													v-if="
														numOpcionesMostrar[lugarExperiencia] !== undefined &&
														numOpcionesMostrar[lugarExperiencia] > 2
													"
													class="boton-mostrar"
													@click="mostrarOpciones(lugarExperiencia, false)">
													{{ $t('general.mostrarMenos') }}
												</div>
												<div
													v-if="
														numOpcionesMostrar[lugarExperiencia] !== undefined &&
														numOpcionesMostrar[lugarExperiencia] < opciones.length
													"
													class="boton-mostrar"
													@click="mostrarOpciones(lugarExperiencia, true)">
													{{ $t('general.mostrarMas') }}
												</div>
											</div>
										</template>
									</div>
								</template>
							</template>
						</template>
					</div>

					<template v-if="informacionAlojamiento.servicios.length > 0">
						<h2 id="servicios" class="fw-bolder mt-4">
							{{ $t('alojamiento.servicioHotel') }}
						</h2>
						<template v-for="(servicio, index) in informacionAlojamiento.servicios" :key="index">
							<template v-if="servicio.serviciosTipo.length > 0">
								<h5 class="fs-4and5 text-muted fw-bold mt-3">
									{{ capitalizeFirstLetter(servicio.tipo) }}
								</h5>
								<div class="row d-flex mb-4 justify-content-around justify-content-md-start">
									<template v-for="(servicioTipo, tipoIndex) in servicio.serviciosTipo" :key="tipoIndex">
										<div class="col-6 col-sm-2 d-flex flex-column align-items-center my-4">
											<img
												:src="servicioTipo.imagen"
												:alt="servicioTipo.nombre"
												width="40"
												height="40"
												style="object-fit: cover" />
											<p class="mb-0 text-center">
												{{ servicioTipo.nombre.charAt(0).toUpperCase() + servicioTipo.nombre.slice(1) }}
											</p>
										</div>
									</template>
								</div>
							</template>
						</template>
					</template>
					<!--
          <h2 class="fw-bolder m-0 mt-4" id="ofertas">
            Mejores Ofertas en Lugar
          </h2>
          <home-carousel
            :carouselData="{
              size: '290',
              itemsScroll: 3,
            }"
            class="mb-4"
          /> -->

					<template v-if="informacionAlojamiento.faqs.length > 0">
						<p id="preguntas" class="fw-bolder mt-5 mb-0 fs-2">
							{{ $t('alojamiento.preguntasFrecuentes') }}
						</p>
						<div id="accordionFlushExample" class="accordion accordion-flush mt-2 mb-4">
							<div v-for="(ques, index) in informacionAlojamiento.faqs" :key="ques" class="accordion-item">
								<p :id="'#flush-heading' + index" class="accordion-header fs-2">
									<button
										class="accordion-button collapsed ms-0 ps-0"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#flush-collapse' + index"
										aria-expanded="false"
										:aria-controls="'flush-collapse' + index">
										{{ ques.pregunta }}
									</button>
								</p>
								<div
									:id="'flush-collapse' + index"
									class="accordion-collapse collapse"
									:aria-labelledby="'#flush-heading' + index"
									data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										{{ ques.respuesta }}
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<div v-else class="container-alojamiento">
			<SpinnerVue />
		</div>
	</div>

	<div
		v-if="!loading"
		id="mapaModalAlojamiento"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="mapaModalAlojamientoLabel"
		aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="mapaModalAlojamientoLabel" class="modal-title">
						{{ $t('alojamiento.ubicacion') }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body">
					<GMapMap :center="position" :zoom="8" map-type-id="terrain" style="min-height: 60vh">
						<GMapMarker :position="position" :clickable="true" :draggable="false">
							<GMapInfoWindow
								:opened="true"
								:options="{
									pixelOffset: {
										width: 10,
										height: 0,
									},
									maxWidth: 320,
									maxHeight: 320,
								}">
								<div class="fw-bolder">
									{{ informacionAlojamiento.nombre }}
								</div>
							</GMapInfoWindow>
						</GMapMarker>
					</GMapMap>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HomeApiService from 'src/services/api/skiandnight/HomeApiService';
	import { useRoute } from 'vue-router';

	export default {
		beforeCreate: async function () {
			const currentRoute = await useRoute();
			const metaData = await HomeApiService.getAlojamientoSeo(currentRoute.params.idAlojamiento);
			if (metaData['titulo'] && metaData['descripcion']) {
				document.title = metaData.titulo;
				document.querySelector("[name='description']").remove();
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = metaData.descripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		},
	};
</script>
<script setup>
	import CarouselAlojamiento from '@/components/Carousel/CarouselAlojamiento.vue';
	import { ref, computed, watch } from 'vue';
	import { buscadorStore } from '@/store_pinia/buscador';
	import SpinnerVue from '@/components/SpinnerLoading.vue';
	import ModalFotos from '@/components/modales/viajesGrupo/ModalFotos.vue';
	import { titleCase, capitalizeFirstLetter } from '@/helpers/filters';
	import { formatDateWithWeekDay } from '@/helpers/dateUtiles';
	import { BuscadorServicios } from '@/router/RouterNames';
	import BuscadorParaTi from '@/components/buscadores/BuscadorParaTi.vue';
	import router from 'src/router';

	const route = useRoute();
	const store_buscador = buscadorStore();
	const filtrosBuscador = computed(() => store_buscador.filtrosBuscador);
	const estacionSeleccionadaBuscador = computed(() => store_buscador.estacionSeleccionada);

	const opcionesPension = store_buscador.opcionesPension;
	const estacionesData = ref({});
	const numOpcionesMostrar = ref({});
	const desplegarEstaciones = ref({});

	watch(
		filtrosBuscador,
		() => {
			let lugaresExperiencia = {};
			let opciones = {};
			let desplegar = {};

			if (filtrosBuscador.value != null) {
				filtrosBuscador.value.areas.forEach(ar => {
					ar.lugaresExperiencia.forEach(est => {
						lugaresExperiencia[est.id] = {
							imagen: est.icono,
							nombre: est.nombre,
							texto: est.descripcion,
						};

						opciones[est.id] = 2;

						desplegar[est.id] = est.id == estacionSeleccionadaBuscador.value;
					});
				});

				estacionesData.value = lugaresExperiencia;
				numOpcionesMostrar.value = opciones;
				desplegarEstaciones.value = desplegar;
			}

			return lugaresExperiencia;
		},
		{ immediate: true }
	);

	const fromBuscador = route.path.includes('buscador');
	const hotelSeleccionadoBuscador = computed(() => store_buscador.hotelSeleccionado);
	const fechasAlojamiento = computed(() => store_buscador.filtrosSeleccionadosBuscador.fechasAlojamiento);

	const updateOpcionesPensionActual = optKey => {
		store_buscador.hotelSeleccionado.currentPension = optKey;
	};

	const loading = ref(true);
	const informacionAlojamiento = ref();
	const mostrarMas = ref(false);
	const position = ref({});
	HomeApiService.getAlojamiento(route.params.idAlojamiento).then(res => {
		loading.value = false;
		informacionAlojamiento.value = res;
		position.value = {
			lat: informacionAlojamiento.value.latitud,
			lng: informacionAlojamiento.value.longitud,
		};

		if (fromBuscador) {
			setTimeout(() => {
				scrollToElement('ofertas');
			}, 200);
		}
	});

	const scrollToElement = element => {
		window.scrollTo({
			top: document.getElementById(element).offsetTop - document.getElementById('desktop-navbar').offsetHeight,
			left: 0,
			behavior: 'smooth',
		});
	};

	const formatMiembrosHabitacion = personas => {
		let textAdulto =
			personas.numAdultos == 0
				? null
				: personas.numAdultos == 1
				? `${personas.numAdultos} adulto`
				: `${personas.numAdultos} adultos`;

		let textNinio =
			personas.numNinios == 0
				? null
				: personas.numNinios == 1
				? `${personas.numNinios} niño`
				: `${personas.numNinios} niños`;

		return textAdulto && textNinio ? `${textAdulto} + ${textNinio}` : textAdulto ? textAdulto : textNinio;
	};

	const mostrarOpciones = (lugarExperiencia, mas) => {
		if (mas) numOpcionesMostrar.value[lugarExperiencia] += 2;
		else numOpcionesMostrar.value[lugarExperiencia] -= 2;
	};

	const saveCombinacion = (combinacion, lugarExperiencia) => {
		store_buscador.setCombinacion(combinacion);
		store_buscador.setEstacionOpcionSeleccionada(lugarExperiencia);
		router.push({ name: BuscadorServicios });
	};
</script>

<style lang="scss" scoped>
	.botones-container {
		padding: 1rem;
		overflow-x: auto;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.container-alojamiento {
		min-height: 80vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btn-primary {
		border-radius: var(--bs-border-radius-xl);
		margin-right: 10px;
	}

	h5,
	h4,
	h6 {
		color: rgba(var(--bs-primary-rgb), 0.75);
	}

	.alojamiento-container {
		max-width: 1440px;
		margin: auto;
	}

	.fs-4and5 {
		font-size: 1.15rem !important;
	}

	.fs-7 {
		font-size: 0.9rem !important;
	}

	.fs-8 {
		font-size: 0.8rem !important;
	}

	.icono-distancia-estacion {
		width: 20px;
		margin-right: 0.05rem;
	}

	.boton {
		cursor: pointer;
		font-size: 0.8rem;
		font-weight: 500;
		padding: 0.5rem 1.5rem;
		margin-left: 1rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.2s ease;
		border: 0;
	}

	.boton-no-fondo {
		background-color: inherit;
		color: var(--bs-primary);
	}

	.boton-no-fondo:hover {
		text-decoration: underline;
	}

	.boton-no-fondo:active {
		color: #080a1d;
	}

	.boton-naranja {
		background-color: var(--bs-secondary);
		color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		max-width: 110px;
	}

	@media screen and (max-width: 500px) {
		.boton-naranja {
			width: 100%;
		}
	}

	.boton-naranja:hover {
		transform: scale(1.1);
		background-color: var(--bs-primary);
		border-color: var(--bs-primary);
	}

	.boton-naranja:active {
		outline: 2px solid rgba(33, 36, 68, 0.5);
	}

	.boton-blanco {
		background-color: white;
		color: #080a1d;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		max-width: 110px;
		min-height: 54px;
	}

	.boton-blanco:hover {
		transform: scale(1.1);
	}

	.boton-blanco:active {
		outline: 2px solid rgba(33, 36, 68, 0.5);
	}

	.cuadro-forfaits {
		border-radius: var(--bs-border-radius-xxl);
		width: 100%;
		background-color: #f9f7f8;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	@media screen and (max-width: 767px) {
		.cuadro-forfaits {
			border-radius: var(--bs-border-radius-lg);
		}
	}

	.boton-cuadro-azul {
		border: solid 1.5px var(--bs-primary);
		border-radius: var(--bs-border-radius);
		color: var(--bs-primary);
		background-color: inherit;
		padding: 0.2rem 1.2rem;
		cursor: pointer;
		box-shadow: 1px -0.5px 2px 0 rgba(0, 0, 0, 0.4);
	}

	.boton-cuadro-azul-selected {
		background-color: var(--bs-primary);
		color: white;
	}

	.img-estacion {
		width: 30px;
	}

	.texto-estacion {
		padding-top: 0.2rem;
		padding-right: 2rem;
	}

	.info-estacion {
		color: #6f6e8e;
		font-weight: 600;
		font-size: 0.9rem;
	}

	.icono-distancia-estacion {
		width: 20px;
	}

	.texto-distancia-estacion {
		color: #0073a4;
		font-size: 0.85rem;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.cuadro-opcion {
		background-color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		width: 100%;
		border-radius: var(--bs-border-radius-xxl);
	}

	@media screen and (max-width: 767px) {
		.cuadro-opcion {
			border-radius: var(--bs-border-radius-lg);
		}
	}

	.texto-desde-precio {
		font-size: 0.6rem;
	}

	.personas {
		margin-left: -13px;
	}

	.personitas {
		margin-top: 5.5px;
		margin-left: 2px;
		width: 1.1rem;
	}

	.texto-cancelacion-total {
		color: #16ab0c;
		font-size: 0.8rem;
	}

	.texto-cancelacion-parcial {
		font-size: 0.8rem;
	}

	.boton-mostrar {
		cursor: pointer;
	}

	.boton-mostrar:hover {
		text-decoration: underline;
	}

	.card-check {
		background-color: #f9f7f8;
		padding: 1.3rem 0.5rem;
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0 4px 2px -2px gray;
	}

	.blog_banner {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin: auto;
		min-height: 65vh;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background-image: url('~@/assets/fotos/footer.webp');
		padding-top: 7rem;

		&-container {
			max-width: 1440px;
			margin: 0 auto;
		}

		&-buttons {
			margin-bottom: 4rem;
		}
	}

	.carousel-imagenes {
		margin-top: -150px;
	}

	div :deep(.gm-ui-hover-effect) {
		display: none !important;
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	.informacion-habitaciones {
		width: 50%;
	}

	@media screen and (max-width: 767px) {
		.informacion-habitaciones {
			width: 100%;
		}
	}

	.modal-dialog {
		max-width: 750px !important;
	}

	.fa-door-open {
		opacity: 0.75;
	}

	.icon-checkin {
		transform: scaleX(-1);
	}
</style>
