<template>
	<teleport to="body">
		<modal-base :show="modalActive" hide-submit hide-cancel :title="$t('reserva.recuperarReserva')" @on-close="close">
			<template #body>
				<RecuperarReservas :grupo="grupo" @close="close" />
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase';
	import RecuperarReservas from '@/components/modales/RecuperarReservas.vue';

	defineProps({
		grupo: {
			type: Object,
			default: null,
		},
		modalActive: {
			type: Boolean,
			default: null,
		},
	});

	const emit = defineEmits(['close-modal']);

	const close = () => emit('close-modal');
</script>

<style lang="css"></style>
