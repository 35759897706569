<template>
	<div>
		<template v-if="isLoading">
			<div>
				<SpinnerVue />
			</div>
		</template>
		<template v-else>
			<template v-if="itemsCarousel.length > 0">
				<div>
					<h2 v-if="$slots.title">
						<slot name="title" />
					</h2>
					<template v-if="buttonText && buttonFunction">
						<span class="text-primary fw-normal button-carousel" @click="buttonFunction">
							{{ buttonText }}
						</span>
					</template>
				</div>

				<slot name="subtitle" />
				<Carousel :wrap-around="false" :touch-drag="true" :breakpoints="breakpoints" :autoplay="autoplayTime">
					<Slide v-for="item in itemsCarousel" :key="item.id">
						<slot name="itemCarousel" :data="item" />
					</Slide>
					<template v-if="navigationButtons && itemsCarousel.length > 1" #addons>
						<Navigation />
					</template>
				</Carousel>
			</template>
		</template>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import SpinnerVue from '@/components/SpinnerLoading.vue';
	import { Carousel, Slide, Navigation } from 'vue3-carousel';
	import { ref } from 'vue';

	export default defineComponent({
		name: 'BaseCarousel',
		components: { Carousel, Slide, Navigation, SpinnerVue },
		props: {
			navigationButtons: {
				type: Boolean,
				required: false,
				default: true,
			},
			itemsCarousel: {
				type: Array,
				required: true,
				default: () => [],
			},
			isLoading: {
				type: Boolean,
				required: true,
				default: true,
			},
			autoplayTime: {
				type: Number,
				required: false,
				default: 0,
			},
			maxElements: {
				type: Number,
				required: false,
				default: -1,
			},
			buttonText: {
				type: String,
				required: false,
				default: () => null,
			},
			buttonFunction: {
				type: Function,
				required: false,
				default: () => null,
			},
		},
		setup(props) {
			const breakpoints = ref({
				0: {
					itemsToShow: 1.2,
					snapAlign: 'start',
				},
				650: {
					itemsToShow: 2,
				},
				900: {
					itemsToShow: 3,
				},
				1200: {
					itemsToShow: props.maxElements == -1 ? 4 : props.maxElements,
				},
			});
			return { breakpoints };
		},
	});
</script>

<style lang="scss" scoped>

	.button-carousel {
		// margin-left: 1rem;
		transition: 0.3s ease-in-out;
		font-size: 12px;

		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
</style>
