<template>
	<div class="d-flex align-items-center flex-row w-100">
		<div
			v-if="imagenes == undefined || imagenes.length == 0"
			class="imagen-hotel imagen-nohotel d-flex align-items-center justify-content-between me-3 w-100" />
		<div
			v-else
			class="imagen-hotel d-flex align-items-center justify-content-between mx-2 w-100"
			:style="`background-image: url(${imagenes[indexCurrentImage]});`">
			<template v-if="imagenes.length > 1">
				<i class="fa-solid fa-chevron-left flechas-img-alojamiento ms-2" @click="anteriorImagen" />
				<i class="fa-solid fa-chevron-right flechas-img-alojamiento me-2" @click="siguienteImagen" />
			</template>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';

	const props = defineProps({
		imagenes: {
			type: Array,
			default: () => [],
		},
	});

	const indexCurrentImage = ref(0);

	const siguienteImagen = () => {
		if (indexCurrentImage.value == props.imagenes.length - 1) {
			indexCurrentImage.value = 0;
		} else {
			indexCurrentImage.value++;
		}
	};

	const anteriorImagen = () => {
		if (indexCurrentImage.value == 0) {
			indexCurrentImage.value = props.imagenes.length - 1;
		} else {
			indexCurrentImage.value--;
		}
	};
</script>

<style scoped>
	.imagen-hotel {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.8;
		width: 200px;
		height: 125px;
		border-radius: var(--bs-border-radius-xl);
	}

	.imagen-nohotel {
		background-size: contain;
		background-image: url('../../assets/img/special_workers/no-imagen-alojamiento.webp');
	}

	.flechas-img-alojamiento {
		font-size: 1.5rem;
		padding: 0.5rem;
		cursor: pointer;
		color: var(--bs-primary);
		text-shadow: 0 1px 1px rgba(var(--bs-secondary-rgb), 1);
	}
</style>
