<template>
	<teleport to="body">
		<modal-base
			:show="loginStore.showLoginModal"
			hide-submit
			hide-cancel
			:title="modalTitle"
			@on-close="loginStore.closeLoginModal">
			<template #backAction>
				<span
					v-show="show !== 'initial'"
					class="text-primary fw-bold"
					style="cursor: pointer"
					@click="handleBackClick()">
					<i class="fa-solid fa-chevron-left me-2 d-inline"></i>
				</span>
			</template>
			<template #body>
				<div class="container-login text-left p-2">
					<template v-if="show === 'initial'">
						<div class="title-field text-primary mb-2">
							<span> {{ $t('home.form.personalInformation.emailLogin') }}</span>
						</div>
						<form autocomplete="on" @submit.prevent="validateEmail(informacionLogin.email)">
							<input
								id="email"
								v-model="informacionLogin.email"
								type="email"
								class="text-primary email-input form-control text-start"
								:placeholder="$t('home.form.personalInformation.email')"
								autocomplete="email"
								name="email"
								required />
							<div class="text-center my-2 my-md-3 mt-md-3">
								<button type="submit" class="btn btn-primary w-100 login-btn m-auto">
									<span class="fw-semibold text-mobile">{{ $t('home.form.personalInformation.continuar') }} </span>
								</button>
							</div>
						</form>
						<div v-if="!isBodoSound" class="divider">
							<span class="divider-text">o</span>
						</div>
						<div v-if="!isBodoSound" class="text-center mt-2 mt-md-3">
							<GoogleLogin :callback="regYLoginGoogle" popup-type="CODE">
								<button
									class="btn btn-primary w-100 google-btn d-flex justify-content-center align-items-center m-auto">
									<img src="@/assets/img/logo_google.svg" alt="Logo de Google" class="me-3" />
									<span class="text-primary fw-semibold text-mobile">
										{{ $t('header.login.continuarGoogle') }}
									</span>
								</button>
							</GoogleLogin>
						</div>
					</template>
					<template v-else>
						<LoginForm
							v-if="!loginFormData.recordar && !loginFormData.fromRecordar"
							:email="informacionLogin.email"
							:show-register="show == 'register'"
							@mostrar-login="mostrarLogin"
							@mostrar-recuperar-password="resetPassword"
							@loading="loading = $event"
							@usuario-logueado="closeModal()" />

						<UsuarioPasswordRecordar
							v-else
							:mail-reset="mailReset"
							:from-login="true"
							@volver-al-login="show = 'initial'"
							@password-reset="closeModal()" />
					</template>

					<div v-if="loading" class="container-loading">
						<SpinnerVue />
					</div>
				</div>
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase';
	import { useLoginStore } from '@/store_pinia/login';
	import { reactive } from '@vue/reactivity';
	import { useStore } from 'vuex';
	import UsuarioApiService from '../../services/api/skiandnight/UsuarioApiService';
	import { ref } from 'vue';
	import SpinnerVue from '../../components/SpinnerLoading.vue';
	import LoginForm from '@/components/form/LoginForm.vue';
	import { useI18n } from 'vue-i18n';
	import { controlErrorRegistrarLoginUsuario } from '@/services/utils/ErrorControl';
	import { GoogleLogin } from 'vue3-google-login';
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';
	import CookiesUtils from '@/services/utils/CookiesUtils';
	import UsuarioPasswordRecordar from '@/views/usuario/UsuarioPasswordRecordar/UsuarioPasswordRecordar.vue';

	defineProps({
		modalActive: {
			type: Boolean,
			default: null,
		},
	});

	const { t } = useI18n();

	const loginStore = useLoginStore();

	const isBodoSound = ref(window.location.hostname.includes('bodosound'));

	const modalTitle = ref(t('header.login.welcome'));

	const loginFormData = reactive({
		fromRecordar: false,
		recordar: false,
	});

	const informacionLogin = reactive({
		email: null,
		password: null,
	});

	const store = useStore();

	const loading = ref(false);

	const show = ref('initial');
	const mailReset = ref();

	function closeModal() {
		loading.value = false;
		show.value = 'initial';
		loginStore.closeLoginModal();
	}

	function validateEmail(email) {
		UsuarioApiService.validateEmail(email)
			.then(response => {
				show.value = response ? 'login' : 'register';
				setModalTitle(response ? 'login' : 'register');
			})
			.catch(err => {
				console.log(err);
				show.value = 'register';
			})
			.finally(() => {
				loading.value = false;
			});
	}

	function handleBackClick() {
		loginFormData.recordar = false;
		loginFormData.fromRecordar = false;
		show.value = 'initial';
		setModalTitle();
	}

	function mostrarLogin() {
		setModalTitle('homeLogin');
		show.value = 'initial';
	}

	function resetPassword(email) {
		setModalTitle('resetPassword');
		mailReset.value = email;
		loginFormData.fromRecordar = true;
		loginFormData.recordar = !loginFormData.recordar;
	}

	function setModalTitle(modalType) {
		switch (modalType) {
			case 'homeLogin':
				modalTitle.value = t('header.login.welcome');
				break;
			case 'login':
				modalTitle.value = t('home.form.personalInformation.welcomeBack');
				break;
			case 'register':
				modalTitle.value = t('header.login.tusDatos');
				break;
			case 'resetPassword':
				modalTitle.value = t('header.login.restablecerPassword');
				break;
			default:
				modalTitle.value = t('header.login.welcome');
				break;
		}
	}

	function regYLoginGoogle(callbackResponse) {
		loading.value = true;
		UsuarioApiService.registrarYLoguearGoogle(callbackResponse)
			.then(response => {
				localStorage.setItem('jwt-mg', response.token);
				alertAutoCloseSuccessWithoutCallback(2000);
				const usuario = CookiesUtils.getUsuarioFromLocalStorage();
				store.commit('login/LOGIN_SUCCESS', usuario);
				loading.value = false;
				closeModal();
			})
			.catch(err => {
				loading.value = false;
				controlErrorRegistrarLoginUsuario(err);
			});
	}
</script>

<style lang="scss" scoped>
	:deep(.modal-container) {
		@media only screen and (min-width: 768px) {
			min-width: unset;
			max-width: unset;
			width: 800px;
		}

		@media only screen and (max-width: 768px) {
			padding-bottom: 10px;
		}
	}

	:deep(.modal-body) {
		margin: 10px !important;
		@media only screen and (max-width: 768px) {
			margin-bottom: 5px;
		}
	}

	:deep(.title) {
		@media only screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	.container-login {
		border-radius: var(--bs-border-radius-xl) !important;

		@media only screen and (max-width: 768px) {
			padding: 0;
		}

		.title {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.309px;
		}

		.title-field {
			font-size: 14px;
			font-weight: 400;
			@media only screen and (max-width: 768px) {
				font-size: 12px;
			}
		}

		.divider {
			text-align: center;
			position: relative;
			opacity: 50%;
		}

		.divider-text {
			background-color: white;
			padding: 0 10px;
			position: relative;
			z-index: 1;
			color: var(--bs-primary);
			opacity: 100%;
		}

		.divider::after {
			content: '';
			background: var(--bs-primary);
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 1px;
			z-index: 0;
		}
	}

	.login {
		max-width: 550px;
		margin: auto;
	}

	.register {
		max-width: 850px;
		margin: auto;
	}

	.cambio-vista {
		cursor: pointer;
		transition: 0.3s ease-in-out;
		color: white;

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.cambio-vista-2 {
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.container-loading {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		background-color: #80808059;
	}

	.form-check-input:checked[type='checkbox'],
	.form-check-input[type='checkbox'] {
		border-radius: 0;
	}

	.email-input {
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset !important;
		border-radius: var(--bs-border-radius) !important;
		font-size: 16px;

		@media only screen and (max-width: 768px) {
			font-size: 12px;
		}
	}

	.login-btn {
		box-shadow: 0 1px 2px 0 rgba(var(--bs-primary-rgb), 0.35) !important;
		font-size: 16px;
		@media only screen and (max-width: 768px) {
			font-size: 14px;
		}
	}

	.google-btn {
		border: 1px solid var(--bs-primary) !important;
		box-shadow: none !important;
		background-color: transparent !important;
		transition: all 0.3s ease;
		@media only screen and (max-width: 768px) {
			font-size: 14px !important;
		}
	}

	.google-btn:hover {
		background-color: var(--bs-primary) !important;

		span {
			transition: all 0.5s ease;
			color: #ffffff !important;
		}
	}

	.google-text:hover {
	}

	.g-btn-wrapper {
		width: 100% !important;
	}

	.text-mobile {
		@media only screen and (max-width: 768px) {
			font-size: 14px !important;
			font-weight: 600 !important;
		}
	}
</style>
