<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
		<rect width="74" height="74" rx="6" fill="white" />
		<rect x="14" y="14" width="16" height="16" stroke="var(--bs-primary)" stroke-width="2" />
		<rect x="14" y="44" width="16" height="16" stroke="var(--bs-primary)" stroke-width="2" />
		<rect x="44" y="14" width="16" height="16" stroke="var(--bs-primary)" stroke-width="2" />
		<rect x="17.5" y="17.5" width="9" height="9" fill="var(--bs-primary)" />
		<rect x="17.5" y="47.5" width="9" height="9" fill="var(--bs-primary)" />
		<rect x="47.5" y="17.5" width="9" height="9" fill="var(--bs-primary)" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M33.25 17.5H35.5V20.5H40V17.5H41.5V18.25V23.5H35.5V28H41.5V29.5H40H38.5V31V32.5H40V31H41.5V32.5V33.25H49V35.5H47.5V37H46V38.5H47.5H49V37H55V38.5V40H56.5V38.5V37H55V33.25H59.5V35.5H58V37H59.5H61V40H59.5V41.5H56.5V46H55V47.5H56.5V46H58V47.5V49H59.5V50.5H61V53.5H59.5V52H58V53.5H56.5V58H61V61H53.5V55H55V53.5V52H53.5V50.5H52V52H47.5V58H46V59.5H47.5V61H41.5H40V59.5H41.5H43V58H41.5H40V56.5H44.5V55V52V50.5H46V49H47.5V47.5H46H44.5V49V50.5H40V52H35.5V55H34V56.5H35.5V58V59.5V61H33.25V41.5H32.5H31V40H28V38.5H26.5V40H25V38.5H20.5V37H19V38.5H13V37H14.5V35.5H13V34H16V35.5H17.5V34H19V35.5H23.5V37H25V35.5H29.5V37H31H32.5V35.5H31H29.5V32.5H31L32.5 32.5L33.25 32.5V17.5ZM59.5 49V47.5V46H61V49H59.5ZM44.5 40H49H50.5V41.5H49V44.5H44.5V40ZM35.5 38.5V40H37V41.5H40H41.5V43H40V46H37V47.5V49H35.5V47.5V46V41.5H34V40V38.5H35.5ZM34 34H35.5H37V35.5V37H35.5V35.5H34V34ZM35.5 31H37V32.5H35.5H34V31H35.5ZM41.5 44.5H43V46V47.5H41.5V46V44.5ZM41.5 47.5V49H40V47.5H41.5ZM43 38.5V35.5H40V37H38.5V38.5H40H43ZM53.5 41.5H55V43H53.5H52V41.5H53.5ZM52 47.5V46H49V49H52H53.5V47.5H52Z"
			fill="var(--bs-primary)" />
		<path
			d="M8 20V10C8 8.89543 8.89543 8 10 8H20"
			stroke="var(--bs-primary)"
			stroke-width="2.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M66 54L66 64C66 65.1046 65.1046 66 64 66L54 66"
			stroke="var(--bs-primary)"
			stroke-width="2.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M54 8L64 8C65.1046 8 66 8.89543 66 10L66 20"
			stroke="var(--bs-primary)"
			stroke-width="2.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M20 66L10 66C8.89543 66 8 65.1046 8 64L8 54"
			stroke="var(--bs-primary)"
			stroke-width="2.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script setup></script>

<style lang="css"></style>
