<template>
	<div class="card tarjeta">
		<div class="fondo-tarjeta">
			<template v-if="apresSki.imagenPrincipal">
				<img class="img-tarjeta" :src="apresSki.imagenPrincipal" alt="" />
			</template>
			<template v-else>
				<div class="img-tarjeta" src="../../assets/fotos/est1.webp" alt="" />
			</template>
			<div class="card-body seccion-inferior-tarjeta ps-0">
				<div class="titulo">
					{{ apresSki.nombre }}
				</div>
				<div class="descripcion">
					{{ showOnlyTwoLines(apresSki.descripcion) }}
				</div>
				<div class="saber-mas text-primary" @click="goToDetallesApresSki(apresSki.id)">
					{{ $t('general.quieroSaberMas') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useRouter } from 'vue-router';
	import { showOnlyTwoLines } from '@/helpers/filters';

	const router = useRouter();

	defineProps({
		apresSki: {
			type: Object,
			default: null,
		},
	});

	const goToDetallesApresSki = async apresSkiId => {
		router.push({
			name: 'ApresSkisDetalle',
			params: {
				apresSkiId: apresSkiId,
			},
		});
	};
</script>

<style lang="css" scoped>
	.tarjeta {
		background-color: transparent;
		width: 95%;
		height: 95%;
		border: 0px !important;
	}

	.fondo-tarjeta {
		border-radius: var(--bs-border-radius-xxl);
		height: 100%;
		background-position: center;
		background-size: cover;
	}

	.seccion-inferior-tarjeta {
		background-color: white;
		bottom: 0;
		height: 20%;
		width: 100%;
	}
	.titulo {
		color: var(--bs-primary);
		text-align: start;
		font-size: 1.2rem;
		font-weight: 700;
	}
	.descripcion {
		color: #6f6e8e;
		text-align: start;
		font-size: 0.8rem;
		font-weight: 700;
	}

	.img-tarjeta {
		background-size: cover;
		background-position: center;
		border-radius: var(--bs-border-radius-xxl);
		width: 100%;
		height: 250px;
	}

	.saber-mas {
		font-weight: 600;
		margin-top: 1rem;
		text-align: start;
		color: var(--bs-secondary);
		cursor: pointer;
		text-decoration: underline;
	}

	.saber-mas:hover {
		text-decoration: underline;
	}

	@media (max-width: 575px) {
		.img-tarjeta {
			margin: auto;
		}

		.seccion-inferior-tarjeta {
			margin: auto;
			width: 100%;
		}
	}
</style>
