<template>
	<div class="card shadow text-primary card-resumen">
		<div class="card-body">
			<p class="fs-5 fw-bold mb-0">
				{{ serviciosEvento.nombre }}
			</p>
			<p class="mb-0">Localizacion : {{ informacionFestival.ubicacion }}</p>
			<button class="btn btn-link" @click="openEvento(informacionFestival.uri)">
				<i class="fa-solid fa-circle-info me-2" />
				Mas informacion
			</button>
			<div v-if="calcularTotalReserva(resumenReserva()) > 0" class="card shadow-sm">
				<div class="card-body">
					<template v-for="servicioReservado in resumenReserva()" :key="servicioReservado.nombre">
						<div
							v-if="servicioReservado.cantidad > 0 && servicioReservado.tickets.length > 0"
							class="mb-3 overflow-hidden">
							<div class="text-primary fw-bold">
								{{ servicioReservado.nombre }}
								<hr class="mt-0" />
							</div>
							<template v-for="ticket in servicioReservado.tickets" :key="ticket.id">
								<div class="mb-3">
									<div v-if="servicioReservado.tipo != 'consumicion'" class="row">
										<div class="col-9">
											{{ servicioReservado.cantidad }} x
											{{ ticket.nombre }}
										</div>
									</div>
									<div v-if="servicioReservado.tipo == 'consumicion'" class="row">
										<div class="col-9">
											{{ ticket.cantidad }} x
											{{ ticket.nombre }}
										</div>
										<div class="col-3 text-secondary fw-bold text-end">
											{{ ticket.cantidad * ticket.precio }}
											€
										</div>
									</div>
								</div>
							</template>
							<div class="row">
								<hr class="mt-0" />
								<div class="col-9 fw-bold text-primary fs-6">
									{{ $t('subtitulo.total') }}
								</div>
								<div class="col-3 text-end fw-bold text-secondary">
									{{ calcularTotalServicio(servicioReservado) }}
									€
								</div>
							</div>
						</div>
					</template>
				</div>
				<div v-if="serviciosEvento.repercutirFee" class="card-body">
					<div class="row pt-0">
						<div class="col-9">{{ $t('servicios.repercutirFee') }}</div>
						<div class="col-3 fw-bold text-secondary">
							{{
								serviciosEvento.repercutirFee
									? roundUp(serviciosEvento.fee * calcularTotalReserva(resumenReserva()))
									: 0
							}}€
						</div>
					</div>
				</div>
				<div class="card-footer bg-primary">
					<div class="d-flex justify-content-between">
						<span class="fs-5 fw-bold text-secondary">{{ $t('reserva.totalReserva') }}</span>
						<span class="text-secondary fw-bold">
							{{
								serviciosEvento.repercutirFee
									? calcularTotalReserva(resumenReserva()) +
									  roundUp(serviciosEvento.fee * calcularTotalReserva(resumenReserva()))
									: calcularTotalReserva(resumenReserva())
							}}
							€
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { FestivalesDetalle } from 'src/router/RouterNames';
	import { festivalesStore } from 'src/store_pinia/festivales';
	import { useRouter } from 'vue-router';

	defineProps({
		serviciosEvento: {
			type: Object,
			default: null,
		},
		informacionFestival: {
			type: Object,
			default: null,
		},
	});
	const router = useRouter();
	const store_festivales = festivalesStore();
	const reservaServiciosFestivales = computed(() => store_festivales.reservaServiciosFestivales);
	const openEvento = uriNombre => {
		const routeData = router.resolve({
			name: FestivalesDetalle,
			params: { uri: uriNombre },
		});
		window.open(routeData.href, '_blank');
	};

	const calcularTotalReserva = resumenReserva => {
		let auxTotal = 0;
		resumenReserva.map(servicio => {
			if (servicio.cantidad && servicio.tickets.length > 0) {
				if (servicio.tipo == 'consumicion') {
					servicio.tickets.forEach(ticket => {
						auxTotal += ticket.cantidad * ticket.precio;
					});
				} else {
					if (servicio.subtipo == 'full') {
						auxTotal += servicio.cantidad * servicio.precio * servicio.tickets.length;
					} else {
						auxTotal += servicio.precio * servicio.cantidad;
					}
				}
			}
		});
		return auxTotal;
	};

	const roundUp = (valueFloat, decimals = 2) => {
		var factor = Math.pow(10, decimals);
		return Math.ceil(valueFloat * factor) / factor;
	};

	const calcularTotalServicio = servicioReservado => {
		let totalAux = 0;
		servicioReservado.tickets.map(ticket => {
			if (servicioReservado.tipo == 'consumicion') {
				totalAux += ticket.cantidad * ticket.precio;
			} else {
				if (servicioReservado.subtipo == 'full') {
					totalAux = servicioReservado.cantidad * servicioReservado.precio * servicioReservado.tickets.length;
				} else {
					totalAux = servicioReservado.cantidad * servicioReservado.precio;
				}
			}
		});
		return totalAux;
	};

	const resumenReserva = () => {
		let serviciosAux = [];
		Object.keys(reservaServiciosFestivales.value).map(servicio => {
			serviciosAux.push({
				...reservaServiciosFestivales.value[servicio],
			});
		});
		return serviciosAux;
	};
</script>

<style lang="css" scoped>
	.card-resumen {
		border-radius: var(--bs-border-radius-xxl);
		background-color: #f9f7f8;
	}
</style>
