<template>
	<div class="container superior">
		<div class="row titulo">
			<div class="col">POLÍTICA DE CAMAS LIBRES</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-toggle="collapse"
						href="#collapseOne"
						role="button"
						aria-expanded="false"
						aria-controls="collapseOne">
						1. POLÍTICA DE CANCELACIÓN DE CAMAS LIBRES.
					</a>
				</p>
				<div id="collapseOne" class="collapse">
					<div class="card card-body">
						<p>
							Si un cliente desiste del viaje, después de la formalización del depósito exigido por considerar firme la
							reserva, tendría que indemnizar a la agencia de viajes con el abono de la siguientes sumas:
						</p>
						<p class="p-amarillo">
							El 15% del importe total del viaje si la anulación se produce antes de los 25 dí­as naturales anteriores a
							la salida
						</p>
						<p class="p-amarillo">El 50% si se produce antes de 15 dí­as naturales antes de la salida</p>
						<p class="p-amarillo">El 100% si es dentro de los 15 dí­as naturales antes de la salida</p>
						<p>
							La reserva del viaje no se devolverá bajo ninguna circunstancia. En el caso de no cumplir los plazos de
							pago establecido, se cancelará la reserva automáticamente lo que supondrá que el resto de componentes de
							la habitación asumirán el gasto de la cama libre.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="css" scoped>
	.superior {
		padding-top: 7rem;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 1.5rem;
		margin-bottom: 2rem;
		font-weight: 900;
	}

	.btn {
		width: 100%;
		text-align: start;
		padding-left: 2rem;
		background-image: linear-gradient(72deg, #313056 31%, #313056 31%, #30356b 91%);
	}

	.collapse {
		margin-bottom: 1rem;
	}

	.spanned {
		font-weight: 900;
	}

	.p-amarillo {
		color: var(--bs-secondary);
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}
		.titulo {
			font-size: 2rem;
		}
		.p-amarillo {
			color: var(--bs-secondary);
		}
	}
</style>
