<template>
	<div class="container-login text-left p-4">
		<span v-show="show !== 'initial'" class="text-primary fw-bold" style="cursor: pointer" @click="handleBackClick()">
			<i class="fa-solid fa-chevron-left me-2 d-inline"></i>
		</span>
		<template v-if="show === 'initial'">
			<h4 class="text-primary title pb-3">{{ $t('home.form.personalInformation.iniciarSesionRegistrarReservar') }}</h4>

			<span class="title-field text-primary mb-2">
				{{ $t('home.form.personalInformation.emailLogin') }}
			</span>
			<form autocomplete="on" @submit.prevent="validateEmail(informacionLogin.email)">
				<input
					id="email"
					v-model="informacionLogin.email"
					type="email"
					class="form-control"
					:placeholder="$t('home.form.personalInformation.email')"
					autocomplete="email"
					name="email"
					required />
				<div class="my-3 text-center">
					<button type="submit" class="btn btn-primary w-100">
						{{ $t('home.form.personalInformation.continuar') }}
					</button>
				</div>
			</form>

			<div v-if="!isBodoSound" class="divider">
				<span class="divider-text">o</span>
			</div>
			<div v-if="!isBodoSound" class="text-center mt-1">
				<GoogleLogin :callback="regYLoginGoogle" popup-type="CODE" class="w-100">
					<button class="btn btn-primary w-100 google-btn d-flex justify-content-center align-items-center m-auto">
						<img src="@/assets/img/logo_google.svg" alt="Logo de Google" class="me-3" />
						<span class="text-primary fw-semibold text-mobile">
							{{ $t('header.login.continuarGoogle') }}
						</span>
					</button>
				</GoogleLogin>
			</div>
		</template>
		<template v-else>
			<LoginForm
				:email="informacionLogin.email"
				:show-register="show == 'register'"
				:from-flujo-reserva="true"
				@mostrar-login="show = 'initial'"
				@mostrar-recuperar-password="mostrarRecuperarPassword"
				@loading="loading = $event"
				@usuario-logeado="$emit('logueado')" />
		</template>

		<div v-if="loading" class="container-loading">
			<SpinnerVue />
		</div>
	</div>
</template>

<script>
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';
	import { reactive } from '@vue/reactivity';
	import { createToaster } from '@meforma/vue-toaster';
	import { useStore } from 'vuex';
	import LoginApiService from '../../services/api/skiandnight/LoginApiService';
	import UsuarioApiService from '../../services/api/skiandnight/UsuarioApiService';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import CookiesUtils from '../../services/utils/CookiesUtils';
	// import Datepicker from '@vuepic/vue-datepicker';
	import { controlErrorRegistrarLoginUsuario } from '@/services/utils/ErrorControl';
	import { ref } from 'vue';
	import SpinnerVue from '../../components/SpinnerLoading.vue';
	import LoginForm from '@/components/form/LoginForm.vue';
	import router from '@/router';
	import { GoogleLogin } from 'vue3-google-login';

	export default {
		components: { GoogleLogin, SpinnerVue, LoginForm /*, Datepicker */ },
		props: {
			// showRegistrarInicial: {
			// 	type: Boolean,
			// 	required: false,
			// 	default: true,
			// },
			isComprador: {
				type: Boolean,
				required: false,
				default: true,
			},
		},
		emits: ['logueado', 'recordar'],
		setup(props, { emit }) {
			const informacionLogin = reactive({
				email: null,
				password: null,
			});

			const store = useStore();

			// const flowCalendar = ref(['year', 'month', 'calendar']);

			const storeHabitacion = informacionHabitacion();

			// const showRegistrar = ref();

			const loading = ref(false);

			const show = ref('initial');

			const isBodoSound = ref(window.location.hostname.includes('bodosound'));

			const openInTab = routeName => {
				window.open(router.resolve({ name: routeName }).href, '_blank');
			};

			const usuarioRegistro = reactive({
				nombre: null,
				apellidos: null,
				telefono: null,
				email: null,
				emailConfirm: null,
				fechaNacimiento: null,
				mailingPublicidadActivo: null,
			});

			const toaster = createToaster({ position: 'top-right' });

			const validateEmail = email => {
				UsuarioApiService.validateEmail(email)
					.then(response => {
						show.value = response ? 'login' : 'register';
					})
					.catch(err => {
						console.log('Error de validacion de email');
						console.log(err);
						show.value = 'register';
					})
					.finally(() => {
						loading.value = false;
					});
			};

			function mostrarRecuperarPassword(email) {
				emit('recordar', email);
			}

			function handleBackClick() {
				show.value = 'initial';
			}

			const login = (email, password) => {
				loading.value = true;
				if (!email) {
					toaster.error('Ingrese el email');
					loading.value = false;
					return;
				}
				if (!password) {
					toaster.error('Ingrese el password');
					loading.value = false;
					return;
				}
				LoginApiService.login(email, password)
					.then(res => {
						loading.value = false;
						localStorage.setItem('jwt-mg', res.token);
						alertAutoCloseSuccessWithoutCallback(2000);
						const usuario = CookiesUtils.getUsuarioFromLocalStorage();

						if (storeHabitacion.reservas.length > 0 && storeHabitacion.reservas[0].reservaPropia) {
							storeHabitacion.reservas[0].usuario = {
								nombre: usuario.nombre,
								apellido: usuario.apellidos.split(' ')[0],
							};
						}
						store.commit('login/LOGIN_SUCCESS', usuario);
						emit('logueado');
					})
					.catch(err => {
						loading.value = false;

						const errorStatus = err.response.status;

						if (errorStatus === 401) {
							toaster.error('Los datos de usuario son invalidos');
						}
					});
			};

			// const showRegistrarFunc = () => {
			// 	showRegistrar.value = !showRegistrar.value;
			// };

			function regYLoginGoogle(callbackResponse) {
				loading.value = true;
				UsuarioApiService.registrarYLoguearGoogle(callbackResponse)
					.then(response => {
						localStorage.setItem('jwt-mg', response.token);
						alertAutoCloseSuccessWithoutCallback(2000);
						const usuario = CookiesUtils.getUsuarioFromLocalStorage();
						store.commit('login/LOGIN_SUCCESS', usuario);
						loading.value = false;
					})
					.catch(err => {
						loading.value = false;
						controlErrorRegistrarLoginUsuario(err);
					});
			}

			const format = date => {
				return date.toISOString().split('T')[0];
			};
			return {
				informacionLogin,
				login,
				show,
				usuarioRegistro,
				loading,
				openInTab,
				format,
				validateEmail,
				regYLoginGoogle,
				mostrarRecuperarPassword,
				handleBackClick,
				isBodoSound,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.container-login {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(33, 36, 68, 0.25);

		.title {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.309px;
		}

		.title-field {
			font-size: 14px;
			font-weight: 400;
		}

		.divider {
			text-align: center;
			margin: 10px 0;
			position: relative;
		}

		.divider-text {
			background-color: white;
			padding: 0 10px;
			position: relative;
			z-index: 1;
		}

		.divider::after {
			content: '';
			background: rgba(33, 36, 68, 0.3);
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 1px;
			z-index: 0;
		}
	}

	.login {
		max-width: 550px;
		margin: auto;
	}

	.register {
		max-width: 850px;
		margin: auto;
	}

	.cambio-vista {
		cursor: pointer;
		transition: 0.3s ease-in-out;
		color: white;

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.cambio-vista-2 {
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.container-loading {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		background-color: #80808059;
	}

	.form-check-input:checked[type='checkbox'],
	.form-check-input[type='checkbox'] {
		border-radius: 0;
	}

	.google-btn {
		border: 1px solid var(--bs-primary) !important;
		box-shadow: none !important;
		background-color: transparent !important;
		transition: all 0.3s ease;
		@media only screen and (max-width: 768px) {
			font-size: 14px !important;
		}
	}

	.google-btn:hover {
		background-color: var(--bs-primary) !important;

		span {
			transition: all 0.5s ease;
			color: #ffffff !important;
		}
	}
</style>
