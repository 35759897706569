const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'agos', 'sep', 'oct', 'nov', 'dic'];

const weekDays = ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'];

export const namesMonths = (lan = 'es-Es') => {
	if (lan === 'es-Es') {
		return {
			0: 'Enero',
			1: 'Febrero',
			2: 'Marzo',
			3: 'Abril',
			4: 'Mayo',
			5: 'Junio',
			6: 'Julio',
			7: 'Agosto',
			8: 'Septiembre',
			9: 'Octubre',
			10: 'Noviembre',
			11: 'Diciembre',
		};
	} else {
		return {
			0: 'January',
			1: 'February',
			2: 'March',
			3: 'April',
			4: 'May',
			5: 'June',
			6: 'July',
			7: 'August',
			8: 'September',
			9: 'October',
			10: 'November',
			11: 'December',
		};
	}
};

export const formatDatesDDmm = date => {
	const parsedDateFromISO = new Date(date);
	const day = parsedDateFromISO.getDate();
	const month = months[parsedDateFromISO.getMonth()];
	return day + ' ' + month;
};

export const formatDates = date => {
	const parsedDateFromISO = new Date(date);
	const day = parsedDateFromISO.getDate();
	const month = months[parsedDateFromISO.getMonth()];
	const year = parsedDateFromISO.getFullYear();
	return day + ' de ' + month + '. ' + year;
};

export const fomatDatesHyphen = date => {
	const parsedDateFromISO = new Date(date);
	const day = addZero(parsedDateFromISO.getDate());
	const month = addZero(parsedDateFromISO.getMonth() + 1);
	const year = parsedDateFromISO.getFullYear();
	return day + '-' + month + '-' + year;
};

export const formatDateSlashes = date => {
	const parsedDateFromISO = new Date(date);
	const day = addZero(parsedDateFromISO.getDate());
	const month = addZero(parsedDateFromISO.getMonth() + 1);
	const year = parsedDateFromISO.getFullYear();
	return day + '/' + month + '/' + year;
};

export const fomatDatesHyphenPost = date => {
	const parsedDateFromISO = new Date(date);
	const day = addZero(parsedDateFromISO.getDate());
	const month = addZero(parsedDateFromISO.getMonth() + 1);
	const year = parsedDateFromISO.getFullYear();
	return year + '-' + month + '-' + day;
};

export const formatDateText = date => {
	const parsedDateFromISO = new Date(date);
	const day = addZero(parsedDateFromISO.getDate());
	const month = addZero(parsedDateFromISO.getMonth() + 1);
	const year = parsedDateFromISO.getFullYear();
	return day + ' de ' + month + 'del ' + year;
};

export const formatDatesSm = date => {
	const parsedDateFromISO = new Date(date);
	const day = parsedDateFromISO.getDate();
	const month = months[parsedDateFromISO.getMonth()];
	return day + ' ' + month;
};

export const formatDatesFullMonth = date => {
	const parsedDateFromISO = new Date(date);
	const day = parsedDateFromISO.getDate();
	const month = namesMonths()[parsedDateFromISO.getMonth()];
	return day + ' ' + month;
};

export const getFullYearDate = date => {
	const parsedDateFromISO = new Date(date);
	const year = parsedDateFromISO.getFullYear();
	return year;
};

export const formatDatesFullMonthAndYear = date => {
	const parsedDateFromISO = new Date(date);
	const day = parsedDateFromISO.getDate();
	const month = namesMonths()[parsedDateFromISO.getMonth()].toString().toUpperCase();
	const year = parsedDateFromISO.getFullYear();
	return day + ' ' + month + ' ' + year;
};

export const formatString = date => {
	const day = addZero(date.getDate());
	const month = addZero(date.getMonth() + 1);
	const year = date.getFullYear();
	return day + 'de' + month + 'del' + year;
};

export const addZero = val => {
	let aux = val.toString();
	if (aux.length == 1) {
		return aux.replace(/^/, '0');
	} else {
		return aux;
	}
};

export const dateMonthNumbers = date => {
	const d = new Date(date);
	return date?.split('-', 3)[2] + '/' + addZero(d.getMonth() + 1);
};
export const dateMonthYearDDmmYYYY = date => {
	const d = new Date(date);
	const year = d.getFullYear();
	return date.split('-', 3)[2] + '-' + addZero(d.getMonth() + 1) + '-' + year;
};
export const formatDateWithWeekDay = date => {
	const parsedDateFromISO = new Date(date);
	const weekDay = weekDays[parsedDateFromISO.getDay()];
	const day = parsedDateFromISO.getDate();
	const month = months[parsedDateFromISO.getMonth()];
	return weekDay + ', ' + day + ' ' + month;
};

export const formatMisReservas = date => {
	const parsedDate = new Date(date);
	const utcDay = parsedDate.getUTCDay();
	const day = parsedDate.getUTCDate();
	const month = months[parsedDate.getUTCMonth()];
	const weekDay = weekDays[utcDay];
	const formattedDate = `${weekDay} ${day}, ${month}`;
	return formattedDate;
};

export const convertStringToDate = date => {
	const [year, month, day] = date.split('-');

	const dateAux = new Date(+year, month - 1, +day);
	return dateAux;
};

export const validateDate = date => {
	return !(new Date(date) <= new Date());
};

export const dateMonthNumbersTwo = dateString => {
	if (!dateString) return '';
	const date = new Date(dateString);
	return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const formatDate = date => {
	return formatDatesFullMonth(convertStringToDate(date));
};

export const normalizeDate = dateString => {
	const date = new Date(dateString);
	date.setHours(0, 0, 0, 0);
	return date;
};
