<template>
	<!-- Sección cabecera -->
	<div
		class="d-flex flex-column justify-content-center festival-img-fondo"
		:style="`background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(${require('@/assets/fotos/reserva-festival.webp')})`">
		<div class="align-self-center h-50 w-100 d-flex align-items-center mt-5">
			<BaseMigas
				:migas-text="[
					$t('baseMigas.seleccionFestival'),
					$t('baseMigas.datosReserva'),
					$t('baseMigas.confirmacionReserva'),
					$t('baseMigas.pago'),
				]"
				:posicion="1"
				class="d-none d-md-block my-5" />
		</div>
		<div class="texto-cabecera ms-5 ps-2 d-flex flex-column justify-content-end h-25">
			<p class="text-primary titulo-cabecera">Elige el número de entradas que quieres comprar</p>
			<p class="text-primary">
				Puedes comprar el festival completo o elegir dos noches por semana Lorem ipsum dolor sit amet, consectetuer
				adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat
			</p>
		</div>
	</div>

	<!-- Sección desplegables -->
	<div class="container festivales-detalle">
		<!-- Paquete evento full disabled -->
		<div class="mb-5 opacity-50">
			<!-- Cabecera Paquete evento -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/mountain-fill.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">FULL PACK PRIMERA SEMANA (PROMO 2)</p>
						<p class="subtitulo-desplegable m-0">Incluye cuatro tardes/noches</p>
					</div>
				</div>

				<!-- Precio y boton desplegar-->
				<div class="d-flex gap-5">
					<p class="precio-evento m-0 color-grey">50 €</p>
					<button class="btn btn-primary bg-transparent border-0">
						<i class="fa-solid fa-chevron-up color-grey" />
					</button>
				</div>
			</div>
		</div>

		<!-- Paquete evento full -->
		<div class="mb-5">
			<!-- Cabecera Paquete evento -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/mountain-fill.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">FULL PACK PRIMERA SEMANA (PROMO 2)</p>
						<p class="subtitulo-desplegable m-0">Incluye cuatro tardes/noches</p>
					</div>
				</div>

				<!-- Precio y boton desplegar-->
				<div class="d-flex gap-5">
					<p class="precio-evento m-0">55 €</p>
					<button class="btn btn-primary bg-transparent border-0" @click="openDetailsEvento = !openDetailsEvento">
						<i class="fa-solid fa-chevron-up color-grey" :class="openDetailsEvento ? 'icon-active' : 'icon-inactive'" />
					</button>
				</div>
			</div>

			<!-- Contenido paquete evento -->
			<Transition>
				<div v-if="openDetailsEvento && !loading" class="card mx-0">
					<p>Elige la fecha de inicio de los dos cuatro días durante el festival</p>
					<div class="row align-items-center gx-0">
						<!-- Selección de fechas -->
						<div class="col col-4">
							<div class="dropdown">
								<button
									id="dropdownMenuMiembrosGrupo"
									class="btn boton-miembroActual d-flex flex-row justify-content-between align-items-center"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span>Elige fecha</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-chevron-down"
										viewBox="0 0 16 16">
										<path
											fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</button>

								<ul class="dropdown-menu" aria-labelledby="dropdownMenuMiembrosGrupo">
									<li>
										<button class="dropdown-item" type="button">Elige</button>
									</li>
								</ul>
							</div>
						</div>
						<!-- Selección de cantidad -->
						<div class="col col-5">
							<div class="d-flex align-items-center justify-content-center">
								<div class="me-4">19 de Enero</div>
								<div>
									<div class="d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-minus" />
										</button>
										<span class="mx-2 numero-personas">{{}}0</span>
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-plus" />
										</button>
									</div>
								</div>
							</div>
						</div>
						<!-- Aviso pocas unidades -->
						<div class="col col-3 d-flex justify-content-end align-items-center aviso-unidades">
							<i class="fa-solid fa-circle-info p-2 pointer" />
							<p class="m-0">Quedan pocos tickets</p>
						</div>
					</div>
				</div>
			</Transition>
		</div>

		<!-- Paquete evento 2 días disabled -->
		<div class="mb-5 opacity-50">
			<!-- Cabecera Paquete evento -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/mountain.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">TWO DAYS PACK PRIMERA SEMANA (PROMO 2)</p>
						<p class="subtitulo-desplegable m-0">Incluye dos tardes/noches</p>
					</div>
				</div>

				<!-- Precio y boton desplegar-->
				<div class="d-flex gap-5">
					<p class="precio-evento m-0 color-grey">40 €</p>
					<button class="btn btn-primary bg-transparent border-0">
						<i class="fa-solid fa-chevron-up color-grey" />
					</button>
				</div>
			</div>
		</div>

		<!-- Paquete evento 2 días -->
		<div class="mb-5">
			<!-- Cabecera Paquete evento -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/mountain.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">TWO DAYS PACK PRIMERA SEMANA (PROMO 2)</p>
						<p class="subtitulo-desplegable m-0">Incluye dos tardes/noches</p>
					</div>
				</div>

				<!-- Precio y boton desplegar-->
				<div class="d-flex gap-5">
					<p class="precio-evento m-0">45 €</p>
					<button
						class="btn btn-primary bg-transparent border-0"
						@click="openDetailsEventoDias = !openDetailsEventoDias">
						<i
							class="fa-solid fa-chevron-up color-grey"
							:class="openDetailsEventoDias ? 'icon-active' : 'icon-inactive'" />
					</button>
				</div>
			</div>

			<!-- Contenido paquete evento -->
			<Transition>
				<div v-if="openDetailsEventoDias && !loading" class="card mx-0">
					<p>Elige la fecha de inicio de los dos cuatro días durante el festival</p>
					<div class="row align-items-center gx-0">
						<!-- Selección de fechas -->
						<div class="col col-4">
							<div class="dropdown">
								<button
									id="dropdownMenuMiembrosGrupo"
									class="btn boton-miembroActual d-flex flex-row justify-content-between align-items-center"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span>Elige fecha</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-chevron-down"
										viewBox="0 0 16 16">
										<path
											fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</button>

								<ul class="dropdown-menu" aria-labelledby="dropdownMenuMiembrosGrupo">
									<li>
										<button class="dropdown-item" type="button">Elige</button>
									</li>
								</ul>
							</div>
						</div>
						<!-- Selección de cantidad -->
						<div class="col col-5">
							<div class="d-flex align-items-center justify-content-center">
								<div class="me-4">19 de Enero</div>
								<div>
									<div class="d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-minus" />
										</button>
										<span class="mx-2 numero-personas">{{}}0</span>
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-plus" />
										</button>
									</div>
								</div>
							</div>
							<div class="d-flex align-items-center justify-content-center mt-3">
								<div class="me-4">22 de Enero</div>
								<div>
									<div class="d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-minus" />
										</button>
										<span class="mx-2 numero-personas">{{}}0</span>
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-plus" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>

		<!-- Paquete complemento -->
		<div class="mb-5">
			<!-- Cabecera Paquete complemento -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/plus.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">COMPLEMENTOS</p>
						<p class="subtitulo-desplegable m-0">Haz tu tu experiencia completa</p>
					</div>
				</div>

				<!-- boton desplegar-->
				<div class="d-flex gap-5">
					<button
						class="btn btn-primary bg-transparent border-0"
						@click="openDetailsComplemento = !openDetailsComplemento">
						<i
							class="fa-solid fa-chevron-up color-grey"
							:class="openDetailsComplemento ? 'icon-active' : 'icon-inactive'" />
					</button>
				</div>
			</div>

			<!-- Contenido paquete complemento -->
			<Transition>
				<div v-if="openDetailsComplemento && !loading" class="card mx-0">
					<p class="m-0">
						Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
						dolore magna aliquam erat volutpat
					</p>
					<div class="row align-items-center gx-0 mt-3">
						<!-- Selección informacion de la prenda -->
						<div class="col col-4">
							<!-- titulo -->
							<p class="my-1 titulo-prenda">Camiseta WSF 2023</p>
							<!-- enlace diseño -->
							<div class="d-flex">
								<a href="">Ver diseño</a>
								<p class="ps-2 m-0 precio-prenda">+XX€</p>
							</div>
							<!-- Aviso pocas unidades -->
							<div class="d-flex align-items-center aviso-unidades mt-2">
								<i class="fa-solid fa-circle-info pointer" />
								<p class="ps-2 m-0">Quedan pocas unidades</p>
							</div>
						</div>
						<!-- Selección de tallas -->
						<div class="col col-4 d-flex justify-content-center">
							<div class="dropdown">
								<button
									id="dropdownMenuMiembrosGrupo"
									class="btn boton-miembroActual d-flex flex-row justify-content-between align-items-center"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span>Elige talla</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-chevron-down"
										viewBox="0 0 16 16">
										<path
											fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</button>

								<ul class="dropdown-menu" aria-labelledby="dropdownMenuMiembrosGrupo">
									<li>
										<button class="dropdown-item" type="button">talla</button>
									</li>
								</ul>
							</div>
						</div>
						<!-- Selección de cantidad -->
						<div class="col col-4">
							<div class="d-flex align-items-center justify-content-center">
								<div class="me-4">M</div>
								<div>
									<div class="d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-minus" />
										</button>
										<span class="mx-2 numero-personas">{{}}0</span>
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-plus" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>

		<!-- Paquete consumicion -->
		<div class="mb-5">
			<!-- Cabecera Paquete consumicion -->
			<div class="d-flex align-items-center justify-content-between mt-2 desplegable-evento">
				<!-- Nombre e info paquete -->
				<div class="d-flex align-items-center ps-5">
					<img class="icono-evento" :src="`${require('@/assets/icons_evento/drink.webp')}`" alt="" />
					<div class="py-3 px-4 texto-desplegable">
						<p class="titulo-desplegable m-0">CONSUMICIONES (PROMO 2)</p>
						<p class="subtitulo-desplegable m-0">Incluye cuatro tardes/noches</p>
					</div>
				</div>

				<!-- boton desplegar-->
				<div class="d-flex gap-5">
					<button
						class="btn btn-primary bg-transparent border-0"
						@click="openDetailsConsumicion = !openDetailsConsumicion">
						<i
							class="fa-solid fa-chevron-up color-grey"
							:class="openDetailsConsumicion ? 'icon-active' : 'icon-inactive'" />
					</button>
				</div>
			</div>

			<!-- Contenido paquete consumicion -->
			<Transition>
				<div v-if="openDetailsConsumicion && !loading" class="card mx-0">
					<p class="m-0">
						Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
						dolore magna aliquam erat volutpat
					</p>
					<div class="row align-items-center gx-0 mt-3">
						<!-- Selección informacion de la consumicion -->
						<div class="col col-4">
							<!-- titulo -->
							<p class="my-1 titulo-prenda">Bonocervezas primera semana</p>
							<div class="d-flex">
								<p class="subtitulo-desplegable">3 unidades</p>
								<p class="ps-3 m-0 precio-prenda">+XX€</p>
							</div>
						</div>
						<!-- Selección de cantidad -->
						<div class="col col-4">
							<div class="d-flex align-items-center justify-content-start">
								<div>
									<div class="d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-minus" />
										</button>
										<span class="mx-2 numero-personas">{{}}0</span>
										<button type="button" class="btn btn-outline-primary btn-circle btn-sm">
											<i class="fa-solid fa-plus" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>

	<!-- Sección botones -->
</template>

<script setup>
	import { ref } from 'vue';
	import BaseMigas from '@/components/BaseMigas';

	const openDetailsEvento = ref(false);
	const openDetailsEventoDias = ref(false);
	const openDetailsComplemento = ref(false);
	const openDetailsConsumicion = ref(false);
	const loading = ref(false);
</script>

<style lang="css" scoped>
	.color-grey {
		color: grey !important;
	}

	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.3s ease;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.titulo-cabecera {
		font-weight: 700;
		font-size: 1.5rem;
	}
	.texto-cabecera {
		width: 50%;
	}
	.festivales-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}
	.festival-img-fondo {
		height: 90vh;
		background-size: cover;
		background-position: center;
	}

	.desplegable-evento {
		background-color: #f9f7f8;
		width: 70%;
		box-shadow: 3px 3px #7a7a7a57;
		border-radius: var(--bs-border-radius-xl);
		position: relative;
		z-index: 2;
	}

	.titulo-desplegable {
		font-weight: 700;
		font-size: 1rem;
		color: var(--bs-primary);
	}

	.subtitulo-desplegable {
		color: #6f6e8e;
	}

	.card {
		width: 70%;
		box-shadow: 3px 3px #7a7a7a57;
		border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
		margin-top: -20px;
		padding: 40px 40px 20px 40px;
	}
	.icono-evento {
		height: 2rem;
	}
	.precio-evento {
		font-weight: 700;
		font-size: 1.2rem;
		color: var(--bs-secondary);
	}
	.boton-miembroActual {
		border-radius: var(--bs-border-radius-lg);
		min-width: 10rem;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.aviso-unidades {
		color: red;
		font-weight: 600;
	}

	.numero-personas {
		min-width: 1rem;
		text-align: center;
	}

	.btn-circle.btn-sm {
		width: 30px;
		height: 30px;
		padding: 6px 0px;
		border-radius: var(--bs-border-radius-xl);
		font-size: 8px;
		text-align: center;
		border: #f9f7f8;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.titulo-prenda {
		font-weight: 700;
		font-size: 0.85rem;
	}

	.precio-prenda {
		color: var(--bs-secondary);
	}
	@media (max-width: 575px) {
		.festival-img-fondo {
			min-height: 35vh;
		}
	}

	@media (min-width: 576px) {
		.festivales-detalle {
			padding-top: 5rem;
		}
	}
</style>
