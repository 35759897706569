<template>
	<div class="form">
		<h2 class="mb-5 text-center fw-bolder">
			{{ $t('trabajaNosotros.titulo') }}
		</h2>

		<form @submit.prevent="enviarCv">
			<!--			<div class="mb-3" :class="{ error: v$.nombreCompleto.$errors.length }">-->
			<!--				<h4>{{ $t('home.form.personalInformation.title') }}</h4>-->
			<!--				<input-->
			<!--					type="text"-->
			<!--					class="form-control"-->
			<!--					placeholder="*Nombre y apellidos"-->
			<!--					v-model="form.nombreCompleto"-->
			<!--					@blur="v$.nombreCompleto.$touch"-->
			<!--				/>-->
			<!--			</div>-->

			<div class="mb-3" :class="{ error: v$.nombre.$errors.length }">
				<h4>{{ $t('home.form.personalInformation.title') }}</h4>
				<input
					v-model="form.nombre"
					type="text"
					class="form-control mb-2"
					placeholder="*Nombre"
					@blur="v$.nombre.$touch" />
				<input
					v-model="form.apellidos"
					type="text"
					class="form-control"
					placeholder="*Apellidos"
					@blur="v$.apellidos.$touch" />
			</div>

			<div class="mb-3 row">
				<div class="col-6" :class="{ error: v$.fechaNacimiento.$errors.length }">
					<!-- <input
            type="date"
            class="form-control"
            placeholder="*Fecha de Nacimiento"
            v-model="form.fechaNacimiento"

          /> -->

					<Datepicker
						v-model="form.fechaNacimiento"
						auto-apply
						:format="format2"
						required
						@blur="v$.fechaNacimiento.$touch" />
				</div>
				<div class="col-6" :class="{ error: v$.email.$errors.length }">
					<input
						v-model="form.email"
						type="email"
						class="form-control"
						:placeholder="'*' + $t('home.form.personalInformation.email')"
						@blur="v$.email.$touch" />
				</div>
			</div>

			<div class="mb-3">
				<h4>{{ $t('home.form.personalInformation.title-2') }}</h4>
				<div class="form-floating">
					<textarea
						id="floatingTextarea2"
						v-model="form.observaciones"
						class="form-control"
						placeholder="Leave a comment here"
						style="height: 100px" />
					<label for="floatingTextarea2">
						{{ $t('home.form.personalInformation.write') }}
					</label>
				</div>
			</div>

			<div class="mb-3" :class="{ error: v$.comoConociste.$errors.length }">
				<h4>{{ $t('home.form.personalInformation.howDid') }}</h4>
				<input
					v-model="form.comoConociste"
					type="text"
					class="form-control"
					placeholder="*Escribe aqui"
					@blur="v$.comoConociste.$touch" />
			</div>

			<div class="mb-3">
				<h4>{{ $t('home.form.personalInformation.cv') }}</h4>
				<input
					ref="file"
					class="form-control"
					type="file"
					accept="application/pdf,application/vnd.ms-excel"
					@change="handleFileUpload($event)" />
			</div>

			<div class="mb-3 form-check">
				<input id="exampleCheck1" v-model="form.privacity" type="checkbox" class="form-check-input" />
				<label
					class="form-check-label"
					for="exampleCheck1"
					:class="v$.privacity.$errors.length ? 'text-danger fw-bolder' : ''">
					{{ $t('home.form.policy.readAccept') }}
					<a
						href="https://www.skiandnight.com/old/terminos"
						target="_blank"
						:class="v$.privacity.$errors.length ? 'text-danger fw-bolder' : ''">
						{{ $t('home.form.policy.privacyPolicy') }}
					</a>
				</label>
			</div>
			<button type="submit" class="btn btn-primary">
				{{ $t('botones.enviar') }}
			</button>
		</form>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { reactive, ref } from 'vue';
	import HomeApiService from 'src/services/api/skiandnight/HomeApiService';
	import useValidate from '@vuelidate/core';
	import { required, email } from '@vuelidate/validators';
	import router from 'src/router';
	import Datepicker from '@vuepic/vue-datepicker';
	export default defineComponent({
		components: { Datepicker },
		setup() {
			const form = reactive({
				nombre: null,
				apellidos: null,
				fechaNacimiento: null,
				email: null,
				observaciones: null,
				comoConociste: null,
				file: null,
				privacity: null,
			});

			const newDate = ref();

			const rules = {
				nombre: { required, $autoDirty: false },
				apellidos: { required, $autoDirty: false },
				fechaNacimiento: { required, $autoDirty: false },
				email: { required, email },
				comoConociste: { required },
				privacity: { checked: value => value === true },
			};

			const v$ = useValidate(rules, form);

			let formData = new FormData();

			const handleFileUpload = async e => {
				var files = e.target.files[0];
				formData.append('adjunto', files);
			};

			const format2 = fechaNacimiento => {
				const day1 = fechaNacimiento.getDate();
				const month1 = fechaNacimiento.getMonth() + 1;
				const year1 = fechaNacimiento.getFullYear();

				newDate.value = `${year1}/${month1}/${day1}`;
				return `${day1}/${month1}/${year1}`;
			};

			const enviarCv = () => {
				formData.append('nombre', form.nombre);
				formData.append('apellidos', form.apellidos);
				formData.append('fechaNacimiento', newDate.value);
				formData.append('email', form.email);
				formData.append('observaciones', form.observaciones);
				formData.append('comoConociste', form.comoConociste);
				v$.value.$validate();

				if (!v$.value.$invalid) {
					HomeApiService.sendTrabajaConNosotrosCv(formData).then(() => {
						router.push({ name: 'ThankYou' });
					});
				}
			};

			return { form, handleFileUpload, enviarCv, v$, format2 };
		},
	});
</script>

<style lang="scss" scoped>
	textarea {
		font-size: 20px;
		width: 100%;
		resize: none;
	}

	.form {
		padding: 10rem 1rem 5rem 1rem;
		max-width: 700px;
		margin: auto;
	}

	.error {
		.form-control {
			border-color: #dc3545;
		}
		.dp__main {
			border: 1px solid #dc3545;
		}
	}
</style>
