<template>
	<section class="body">
		<!-- [EXPERIENCE TITLE] -->
		<div class="title-wrapper">
			<h5 v-if="hasTitleScroll" class="title">
				{{ titleUpperCase }}
			</h5>

			<div v-else class="title-auto-scroll-wrapper">
				<div class="title-auto-scroll-content">
					<h3 class="title list-inline">
						{{ titleUpperCase }}
					</h3>
				</div>
			</div>
		</div>

		<!-- [EXPERIENCE DATE FROM/TO] -->
		<div class="label">
			<span class="icon">
				<CalendaryIcon class="icon-calendary" />
			</span>
			<span class="text">{{ experienceDateFromTo }}</span>
		</div>
		<!-- [EXPERIENCE ACCOMMODATION] -->
		<div v-if="hasAlojamiento && startDate !== endDate" class="label">
			<span class="icon">
				<AccommodationIcon class="icon-accommodation" />
			</span>

			<span class="text">
				{{ $t('alojamiento.key') }} {{ numberOfDays(startDate, endDate) }}
				{{
					numberOfDays(startDate, endDate) === 1
						? $t('general.noche').toLocaleLowerCase()
						: $t('general.noches').toLocaleLowerCase()
				}}
			</span>
		</div>

		<!-- [EXPERIENCE FORFAIT] -->
		<div v-if="diasForfait" class="label">
			<span class="icon">
				<ForfaitIcon class="icon-forfait" />
			</span>
			<span v-if="diasForfait === 1" class="text">
				{{ $t('servicios.forfait.titulo') }} {{ diasForfait }} {{ $t('general.dia') }}
			</span>
			<span v-else class="text">
				{{ $t('servicios.forfait.titulo') }} {{ diasForfait }} {{ $t('general.dias').toLocaleLowerCase() }}
			</span>
		</div>

		<!-- [EXPERIENCE INCLUDE SERVICES] -->
		<template v-if="!diasForfait">
			<div v-for="service of filteredExperiences.slice(0, 1)" :key="service.nombre" class="label">
				<span class="icon-wrapper">
					<img
						:src="
							service.isIconoViaje
								? service.imagen
								: require('@/assets/servicios/' + getImagenIconoPorTipoYSubtipo(service.tipo, service.subtipo))
						"
						alt=""
						class="icon" />
				</span>
				<span class="text">{{ service.nombre }}</span>
			</div>
		</template>

		<!-- button ver más/ver menos -->
		<div v-if="filteredExperiences.length > 1 && !hideServices" class="seemore-collapse">
			<div :id="`accordionCollapse${idViaje}`" class="seemore-services collapse">
				<div
					v-for="service of filteredExperiences.slice(1, filteredExperiences.length)"
					:key="service.nombre"
					class="label">
					<span class="icon-wrapper">
						<img
							:src="
								service.isIconoViaje
									? service.imagen
									: require('@/assets/servicios/' + getImagenIconoPorTipoYSubtipo(service.tipo, service.subtipo))
							"
							alt=""
							class="icon" />
					</span>
					<span class="text">{{ service.nombre }}</span>
				</div>
			</div>
			<a
				class="button-collapse"
				data-bs-toggle="collapse"
				:data-bs-target="`#accordionCollapse${idViaje}`"
				type="button"
				@click.stop="toggleList">
				<DownChevronIcon class="me-2" :class="{ 'rotate-icon': isExpanded }" />
				{{ seeMore }}
			</a>
		</div>

		<!-- White spaces containers and uses cases -->
		<div v-if="!diasForfait && filteredExperiences.length === 0" class="white-space"></div>
		<div v-if="!diasForfait && filteredExperiences.length === 1" class="white-space"></div>
		<div v-if="diasForfait && filteredExperiences.length === 1" class="white-space"></div>
		<div v-if="filteredExperiences.length === 0" class="white-space"></div>
	</section>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import { useI18n } from 'vue-i18n';

	import AccommodationIcon from '@/components/Icons/AccommodationIcon.vue';
	import CalendaryIcon from '@/components/Icons/CalendaryIcon.vue';
	import DownChevronIcon from '@/components/Icons/DownChevronIcon.vue';
	import ForfaitIcon from '@/components/Icons/ForfaitIcon.vue';
	import { numberOfDays } from '@/helpers/numberUtils';
	import { availablesLanguages } from '@/helpers/languageUtils';
	import { getImagenIconoPorTipoYSubtipo } from '@/services/utils/RecursosDinamicosUtils';

	const { t } = useI18n();

	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		title: {
			type: String,
			required: true,
		},
		hideServices: {
			type: Boolean,
			required: false,
			default: () => false,
		},
		includeServices: {
			type: Array,
			required: true,
		},
		idViaje: {
			type: Number,
			required: true,
		},
		startDate: {
			require: true,
			type: String,
			default: '',
		},
		endDate: {
			require: true,
			type: String,
			default: '',
		},
		diasForfait: {
			require: true,
			type: Number,
			default: 0,
		},
		hasAlojamiento: {
			require: false,
			type: Boolean,
			default: true,
		},
	});

	const isExpanded = ref(false);
	const browserLanguage = useI18n().locale;

	const filteredExperiences = computed(() => {
		return props.includeServices.filter(service => service.nombre !== 'Alojamiento');
	});

	const seeMore = computed(() => {
		return isExpanded.value ? t('general.verMenos') : t('general.verMas');
	});

	const experienceDateFromTo = computed(() => {
		const parsedDateFromISO = new Date(props.startDate);
		const parsedDateToISO = new Date(props.endDate);
		const options = {
			day: '2-digit',
			month: 'short',
		};

		const selectedLanguage = availablesLanguages.find(lang => lang.id === browserLanguage.value);
		const formatDate = date => new Intl.DateTimeFormat(selectedLanguage.language, options).format(date);
		if (props.startDate == props.endDate) {
			return `${formatDate(parsedDateFromISO).slice(0, 6)}`;
		} else {
			return `${formatDate(parsedDateFromISO).slice(0, 6)} - ${formatDate(parsedDateToISO).slice(0, 6)}`;
		}
	});

	const titleUpperCase = computed(() => {
		return props.title.toString().toUpperCase();
	});

	const hasTitleScroll = computed(() => {
		return props.title.length < 25;
	});

	function toggleList() {
		isExpanded.value = !isExpanded.value;
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		.body {
			@extend .card-body, .border-0;
			padding-bottom: 4px;
			min-height: 180px;

			@include media-breakpoint-up(md) {
				padding-bottom: 10px;
			}

			.title-wrapper {
				@extend .overflow-hidden;

				.title {
					@extend .card-title, .h6, .fw-bold, .mb-3, .text-start;
					color: var(--bs-primary);
					font-size: clamp(0.938rem, 2vw, 1rem); /* min: 15px max: 16px */
				}

				.title-auto-scroll-wrapper {
					@extend .position-relative, .mx-auto, .my-0, .text-nowrap;
					box-sizing: border-box;
					height: 30px;
					width: 800px; // size title  auto-scroll 100% inside a container;

					&:before,
					&:after {
						@extend .z-1, .position-absolute, .top-0;
						content: '';
						height: 30px;
						width: 50px;
					}

					.title-auto-scroll-content {
						@extend .w-100, .d-flex;
						animation-play-state: paused;

						&:hover {
							animation: auto-scroll 8s linear infinite forwards;
						}

						.title .list-inline {
							width: 33.33%;
						}
					}
				}
			}

			.label {
				@extend .d-flex, .align-items-center, .gap-2;
				margin-bottom: 0.375rem;

				.icon-wrapper {
					.icon {
						@extend .d-flex, .align-items-center;
						width: 16px;
					}
				}

				.icon {
					@extend .d-flex, .align-items-center;

					.icon-calendary,
					.icon-accommodation,
					.icon-forfait {
						width: 13px;

						.icon-calendary__path,
						.icon-accommodation__path,
						.icon-forfait__path {
							fill: var(--bs-primary);
						}
					}
				}

				.text {
					@extend .card-text;
					color: var(--bs-primary);
					font-size: clamp(0.813rem, 2vw, 0.875rem); /* min: 13px max: 14px */
				}
			}

			.seemore-collapse {
				.seemore-services {
					.label {
						@extend .d-flex, .align-items-center, .gap-2;
						margin-bottom: 0.375rem;

						.icon-wrapper {
							.icon {
								@extend .d-flex, .align-items-center;
								width: 16px;
							}

							.icon-down-chevron {
								width: 13px;

								.icon-down-chevron__path {
									fill: var(--bs-secondary);
								}
							}
						}

						.text {
							@extend .card-text;
							color: var(--bs-primary);
						}
					}
				}

				.button-collapse {
					@extend .btn, .p-0, .w-100, .text-start;

					color: var(--bs-secondary);
					font-size: clamp(0.75rem, 2vw, 0.813rem); /* min: 12px max: 13px */

					&:hover {
						color: var(--bs-primary);
					}
				}
			}
		}

		/** Transition icon button Ver más*/
		.rotate-icon {
			transform: rotate(180deg);
			transition: transform 0.15s ease;
		}

		/** Animation auto scroll title */

		@keyframes auto-scroll {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-66.6%);
			}
		}
	}

	.white-space {
		width: 100%;
		height: 17px;

		@include media-breakpoint-up(md) {
			height: 18px;
		}
	}
</style>
