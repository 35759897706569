<template>
	<div v-if="storeHabitacion.filtros" style="padding-top: 13rem">
		<template v-if="!loading">
			<div class="row contenedor w-100">
				<p class="col-12 text-primary fw-bold fs-4 text-title" style="cursor: pointer" @click="moveToMisReservas()">
					<i class="fa-solid fa-chevron-left me-2"></i>{{ $t('home.form.backButton') }}
				</p>
				<p class="col-12 text-primary">
					{{ $t('subtitulo.seleccionServicios') }}
				</p>

				<div class="col-md-8 col-12 py-3">
					<div class="contenedor-occupancy justify-content-start">
						<CardOccupancy
							:total-occupancies="hasHabs ? grupo.habitacion.numPlazas : -1"
							:occupants-info="occupants"
							:plazas-libres-repercutidas="hasHabs ? grupo.habitacion.plazasLibresRepercutidas : false"/>
					</div>
				</div>

				<div class="col-md-8 col-12 card-modificar">
					<template v-for="(informacionReserva, index) in grupo.reservas" :key="informacionReserva.id">
						<CardModificarReserva
							:idx-reserva="index"
							:informacion-grupo="grupo" />
					</template>
				</div>
				<div class="col-4 resumen-card">
					<div class="sticky-card">
						<CardLateralReserva :total-reserva="Number(totalReservaCalculada)" />
					</div>
				</div>
			</div>

			<div class="text-center py-3">
				<button class="btn btn-secondary" @click="moveToMisReservas()">
					{{ $t('botones.cancelar') }}
				</button>
				<button class="btn btn-primary" @click="modificarReserva()">
					{{ $t('botones.modificar') }}
				</button>
			</div>
		</template>
	</div>
	<template v-else>
		<div style="height: 80vh" class="d-flex justify-content-center align-items-center">
			<SpinnerVue />
		</div>
	</template>
</template>

<script setup>
	import { useRoute, useRouter } from 'vue-router';
	import { proximasExperiencias } from '@/store_pinia/proximasExperiencias';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { ref, computed, watch, onBeforeMount } from 'vue';
	import CardModificarReserva from './components/CardModificarReserva.vue';
	import SpinnerVue from 'src/components/SpinnerLoading.vue';
	import ReservasApiService from 'src/services/api/skiandnight/ReservasApiService';
	import { alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import CardOccupancy from '@/modules/viajesGrupos/components/CardOccupancy.vue';
	import { occupantsNotManagedReservations, occupantsManagedReservations } from '@/services/utils/GrupoUtils';
	import { calculaPrecioTotalReservas } from '@/helpers/viajesGrupos';
	import CardLateralReserva from '@/components/card/Reserva/CardLateralReserva.vue';
	import {UsuarioProximaReservasConfirmacion, UsuarioProximasReservasDetalle} from '@/router/RouterNames';

	const route = useRoute();
	const router = useRouter();
	const storeHabitacion = informacionHabitacion();
	const grupoId = route.params.grupoId;
	const grupo = ref({});
	const storeProxExp = proximasExperiencias();
	const loading = ref(true);
	const sumaReservas = ref(0);

	const hasHabs = computed(() => grupo.value && grupo.value.habitacion && !grupo.value.habitacion.isComun);

	const totalReservaCalculada = computed(() => {
		return Number(sumaReservas.value).toFixed(2);
	});

	const ocupantesNoGestionados = computed(() => {
		if (grupo.value) {
			return occupantsNotManagedReservations(grupo.value);
		} else {
			return [];
		}
	});

	const ocupantesGestionados = computed(() => {
		if (grupo.value) {
			return occupantsManagedReservations(grupo.value);
		} else {
			return [];
		}
	});

	const occupants = computed(() => [...ocupantesNoGestionados.value, ...ocupantesGestionados.value]);

	function moveToMisReservas() {
		router.push({
			name: UsuarioProximasReservasDetalle,
			params: { grupoId: grupoId },
		});
	}

	function modificarReserva() {
		let reservasModificar = storeHabitacion.reservas.filter(({ habilitarReserva }) => {
			return habilitarReserva;
		});

		let reservaModificarBody = {
			reservas: [],
		};

		if (reservasModificar.length === 0) {
			alertAutoCloseWarningWithoutCallback(3000, 'No hay reservas seleccionadas para modificar');
			return;
		}
		reservasModificar.forEach(reserva => {
			let body = {
				id: reserva.id,
				servicios: Object.values(reserva.servicios).map(servicio => servicio.id),
			};
			reservaModificarBody.reservas.push(body);
		});

		ReservasApiService.doModificarServiciosReservasGrupo(grupoId, reservaModificarBody)
			.then(() => {
				router.push({
					name: UsuarioProximaReservasConfirmacion,
					params: {
						grupoId: grupoId,
					},
				});
			})
			.catch(() => {
				alertAutoCloseWarningWithoutCallback(3000, 'La reserva ya no permite modificaciones');
			});
	}

	async function init() {
		try {
			await storeProxExp.setGrupoSeleccionado(grupoId);
			grupo.value = storeProxExp.getGrupoSeleccionado;

			storeHabitacion.clearReservas();
			storeHabitacion.setInformacionAlojamientoFromRGrupoDTO(grupo.value);
			grupo.value.reservas.map(reserva => {
				// Need to normalise the reservaDTO from reservaAPI to the viajeGrupoDTO one
				// todo -> Ask back to unify both reservaDTOs
				reserva.usuario = {
					nombre: reserva.nombre,
					apellidos: reserva.apellidos,
					// todo -> Normalise to use apellidos
					apellido: reserva.apellidos,
				};
				storeHabitacion.addReserva(reserva);
				storeHabitacion.initOldServicios(reserva);
			});
			sumaReservas.value = calculaPrecioTotalReservas(storeHabitacion.getResumenReservas);
			await storeHabitacion.loadInformacionViaje(grupo.value.viaje.id, grupo.value.viaje.codigo ?? null);
		} finally {
			loading.value = false;
		}
	}

	watch(
		() => storeHabitacion.$state,
		() => {
			storeHabitacion.updateTasaCambio();
		},
		{ deep: true }
	);

	onBeforeMount(() => init());
</script>

<style lang="css" scoped>
	.volver-atras {
		margin-bottom: 1.5rem;
		font-size: 0.9rem;
		font-weight: 700;
		cursor: pointer;
	}

	.resumen-card {
		display: none;
	}

	.card-modificar {
		width: 100%;
	}

	@media only screen and (min-width: 990px) {
		.resumen-card {
			display: block;
		}

		.card-modificar {
			width: 65%;
		}
		.contenedor {
			padding: 0px 48px !important;
		}
	}

	.contenedor {
		padding: 0px 20px;
	}

	.sticky-card {
		position: sticky;
		top: 10rem;
		z-index: 10;
	}
</style>
