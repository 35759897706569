<template>
	<div class="viajes-grupo__container">
		<div class="viajes-grupo__imagen">
			<img src="@/assets/home/ski.webp" class="img-fluid" alt="Ski and Night viajes" title="Viaje Ski and Night" />
		</div>
		<div class="cards-trips viajes-grupo__card-container">
			<template v-for="tipoViaje in tiposViajesGrupos" :key="tipoViaje">
				<router-link
					:to="{
						name: ViajesGrupoCatalogo,
						query: { grupo: tipoViaje.query },
					}">
					<div class="viajes-grupo__card-imagen">
						<span v-sanitize-html="tipoViaje.imagenHtml" />
					</div>
					<div class="viajes-grupo__card-text">
						<div class="viajes-grupo__card-text-titulo">
							{{ tipoViaje.titulo }}
						</div>
						<div class="viajes-grupo__card-text-descripcion">
							{{ tipoViaje.subtitulo }}
						</div>
					</div>
				</router-link>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames';
	import { useI18n } from 'vue-i18n';

	const props = defineProps({
		gruposViajes: {
			type: Object,
			required: true,
			default: () => {},
		},
	});

	const { t } = useI18n();
	// eslint-disable-next-line no-unused-vars
	const viajesGrupo = computed(() => props.gruposViajes);
	const tiposViajesGrupos = computed(() => [
		{
			query: 'colegios',
			imagenHtml: `<img src="${require('@/assets/home/Desktop/ViajesEnGrupo/colegio.svg')}" alt="Icono ${t(
				'home.packs.school.title'
			)}" title="Icono ${t('home.packs.school.title')}" width="70"/>`,
			titulo: t('home.packs.school.title'),
			subtitulo: t('home.packs.school.description'),
		},
		{
			query: 'familias',
			imagenHtml: `<img src="${require('@/assets/home/Desktop/ViajesEnGrupo/familia2.svg')}" alt="Icono ${t(
				'home.packs.family.title'
			)}" title="Icono ${t('home.packs.family.title')}" width="70"/>`,
			imagen: '@/assets/home/Desktop/ViajesEnGrupo/familia2.svg',
			titulo: t('home.packs.family.title'),
			subtitulo: t('home.packs.family.description'),
		},
		{
			query: 'universitarios',
			imagenHtml: `<img src="${require('@/assets/home/Desktop/ViajesEnGrupo/universidad.svg')}" alt="Icono ${t(
				'home.packs.university.title'
			)}" title="Icono ${t('home.packs.university.title')}" width="70"/>`,
			titulo: t('home.packs.university.title'),
			subtitulo: t('home.packs.university.description'),
		},
		{
			query: 'trabajadores',
			imagenHtml: `<img src="${require('@/assets/home/Desktop/ViajesEnGrupo/trabajo.svg')}" alt="Icono ${t(
				'home.packs.company.title'
			)}" title="Icono ${t('home.packs.company.title')}" width="70"/>`,
			titulo: t('home.packs.company.title'),
			subtitulo: t('home.packs.company.description'),
		},
		{
			query: 'premium',
			imagenHtml: `<img src="${require('@/assets/home/Desktop/ViajesEnGrupo/premium.svg')}" alt="Icono ${t(
				'home.packs.premium.title'
			)}" title="Icono ${t('home.packs.premium.title')}" width="70"/>`,
			titulo: t('home.packs.premium.title'),
			subtitulo: t('home.packs.premium.description'),
		},
	]);
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.img-fluid {
		height: 100% !important;
	}
	.viajes-grupo {
		&__container {
			@extend .d-flex, .flex-column, .flex-lg-row, .justify-content-around;
			margin: 35px 0;
		}

		&__imagen {
			@extend .col, .d-flex, .p-3, .justify-content-center, .align-items-center;
			margin: 16px 8px;
		}

		&__card {
			&-container {
				@extend .col, .py-3;

				> a {
					@extend .p-3, .text-primary, .align-items-center, .my-3, .w-100, .mx-auto, .d-flex, .flex-row;
					text-decoration: none;
					box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.6);
					border-radius: var(--bs-border-radius-xl);
					transition: 0.3s ease-in-out;
					padding: 1rem;

					&:hover {
						scale: 1.05;
					}
				}
			}

			&-imagen {
				@extend .col-3, .text-center;
			}

			&-text {
				&-titulo {
					font-size: 1rem;
					font-weight: 600;
				}

				&-descripcion {
					font-size: 0.8rem;
				}
			}
		}
	}
</style>
