export const numeroAMultiplicador = num => {
	switch (num) {
		case 1:
			return 'única';
		case 2:
			return 'doble';
		case 3:
			return 'triple';
		case 4:
			return 'cuádruple';
		case 5:
			return 'quíntuple';
		case 6:
			return 'séxtupla';
		case 7:
			return 'séptupla';
		case 8:
			return 'óctuple';
		case 9:
			return 'nónuple';
		case 10:
			return 'décuple';

		default:
			return num;
	}
};

export const numberOfDays = (inicio, fin) => {
	var d1 = new Date(inicio);
	var d2 = new Date(fin);
	return (d2 - d1) / (1000 * 3600 * 24);
};

export const roundNumbers = (value, precision) => {
	const factor = Math.pow(10, precision);
	return Math.round(value * factor) / factor;
};

export function setNumberToNaturalRounded(num) {
	const numberValue = Number(num);
	if (num === null || num === undefined || isNaN(numberValue)) {
		throw new Error(`Invalid number value: ${num}`);
	}
	let adjustedNumber = Math.round(numberValue * 100) / 100;
	adjustedNumber = Math.round(adjustedNumber * 10) / 10;
	const finalRounded = Math.round(adjustedNumber);
	return finalRounded.toString();
}

// @TODO : QUITAR CUANDO SE AGREGUE CURRENCY
export function setFixedToNumber(num) {
	const numberValue = Number(num);
	if (num === null || num === undefined || isNaN(numberValue)) {
		throw new Error(`Invalid number value: ${num}`);
	}
	return numberValue.toFixed(2);
}
