<template>
	<template v-if="!loading">
		<div v-if="$route.name == 'Landing'">
			<router-view :key="$route.fullPath" />
		</div>

		<div v-else class="container-s">
			<TheHeader />
			<div class="content">
				<div class="wrapper">
					<LoadingAnimation v-if="storeGlobal.loadingGlobal" />
					<router-view :key="$route.fullPath" />
				</div>
			</div>
			<TheFooter />
		</div>
		<cookie-manager />
	</template>
</template>

<script setup>
	import TheHeader from '@/components/TheHeader';
	import TheFooter from '@/components/TheFooter';
	import viajesGrupoStore from './store_pinia/viajes/viajesGrupo';
	import apresSkiStoreModule from '@/store_pinia/viajes/apresSki';
	import CookieManager from '@/components/shared/CookieManager.vue';
	import LoadingAnimation from './components/shared/LoadingAnimation.vue';
	import {onBeforeMount, ref} from 'vue';
	import { useStore } from 'vuex';
	import { appGlobalStore } from '@/store_pinia/app';
	import { addScriptsHeadTags } from 'src/services/utils/ScriptsUtils';
	import HomeApiService from './services/api/skiandnight/HomeApiService';
	import axios from 'axios';

	// reviso cookkie
	const storeGlobal = appGlobalStore();


	const store = useStore();
	const viajesStore = viajesGrupoStore();
	const apresSkiStore = apresSkiStoreModule();
	// This is needed because storeGlobal.loadingGlobal can not be used for showing the hole component
	// due to is changed in lots of inner components
	const loading = ref(true);

	let file = document.createElement('link');
	let headTitle = document.querySelector('head');
	let setFavicon = document.createElement('link');

	function init() {
		storeGlobal.loadingGlobal = true;

		//INITIAL INFORMATION FOR WEB
		viajesStore.setViajes();
		viajesStore.setFiltroViajesGrupos();
		apresSkiStore.setApresSki();

		store.dispatch('login/obtieneInformacionUsuario');
		// si esta la seteo al vuex como login = true

		HomeApiService.getRecursos()
			.then(async res => {
				document.title = res.meta_titulo;
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = res.meta_descripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
				file.rel = 'stylesheet';
				file.href = res.css;
				document.head.appendChild(file);
				storeGlobal.setValoresResources(res);
				selectorIdiomas(res);
				setFavicon.setAttribute('rel', 'shortcut icon');
				setFavicon.setAttribute('href', res.favicon);
				headTitle.appendChild(setFavicon);
				await axios.get(res.permisos).then(value => {
					storeGlobal.setPermisos(value.data);
				});
				addScriptsHeadTags(res.gtm);
			})
			.finally(() => {
				storeGlobal.loadingGlobal = false;
				loading.value = false;
			});
	}

	function selectorIdiomas(response) {
		axios.get(response.translations_en).then(res => {
			storeGlobal.setTranslations(res.data, 'en');
		});
		axios.get(response.translations_es).then(res => {
			storeGlobal.setTranslations(res.data, 'es');
		});
		axios.get(response.translations_ca).then(res => {
			storeGlobal.setTranslations(res.data, 'ca');
		});
		axios.get(response.translations_fr).then(res => {
			storeGlobal.setTranslations(res.data, 'fr');
		});
		axios.get(response.translations_it).then(res => {
			storeGlobal.setTranslations(res.data, 'it');
		});
		axios.get(response.translations_po).then(res => {
			storeGlobal.setTranslations(res.data, 'po');
		});
	}

	onBeforeMount(() => init());

</script>

<style lang="scss">
	@import '~@/assets/scss/main.scss';
	.container-s {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	.content {
		flex: 1;
	}
</style>
