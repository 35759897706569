<template>
	<div>
		<div
			id="desktop-footer"
			class="footer text-white bg-primary"
			:class="{ 'footer-san': storeGlobal.isSeaAndNightUrl }"
			:style="
				storeGlobal.isSeaAndNightUrl ? {} : { 'background-image': 'url(' + storeGlobal.valores.img_footer + ')' }
			">
			<template
				v-if="
					storeGlobal.permisos.nosotros.showed ||
					storeGlobal.permisos.viajes.grupos.ofertas ||
					storeGlobal.permisos.rrss.showed
				">
				<div class="row d-flex w-100 m-auto justify-content-center py-0 py-md-5">
					<div
						v-if="storeGlobal.permisos.nosotros.showed"
						class="order-2 order-md-first col-12 col-md-3 text-center text-md-start my-4 my-md-0">
						<div class="fs-4 fw-bold h5">
							{{ footerData.us.title }}
						</div>
						<template v-for="usItem in footerData.us.items" :key="usItem">
							<div v-if="usItem.show || usItem.show == undefined" class="item h6">
								<template v-if="usItem.link">
									<a :href="usItem.link" target="_blank">
										{{ usItem.label }}
									</a>
								</template>
								<template v-else>
									<router-link :to="{ name: usItem.routeName }">
										{{ usItem.label }}
									</router-link>
								</template>
							</div>
						</template>
					</div>
					<div
						v-if="storeGlobal.permisos.viajes.grupos.ofertas"
						class="order-3 col-12 col-md-3 text-center text-md-start my-4 my-md-0 h5">
						<div class="fs-4 fw-bold h5">
							{{ footerData.groups.title }}
						</div>
						<div class="row">
							<template v-for="group in footerData.groups.items" :key="group">
								<router-link
									:to="{
										name: group.routeName,
										query: { grupo: group.key },
									}"
									class="item h6">
									{{ group.label }}
								</router-link>
							</template>
						</div>
					</div>
					<div v-if="storeGlobal.permisos.rrss.showed" class="order-1 order-md-last col-12 col-md-3 my-4 my-md-0">
						<p class="fw-bold mb-2 text-center text-md-start h5">
							{{ footerData.social.title }}
						</p>
						<div class="row w-75 mx-auto mx-md-0">
							<template v-for="social in footerData.social.items" :key="social">
								<div class="col col-sm-2 item-social">
									<a :href="social.link" target="_blank" :aria-label="social.ariaLabel">
										<i :class="social.icon"></i>
									</a>
								</div>
							</template>
						</div>
					</div></div
			></template>

			<div class="container pb-3 copyright d-flex text-center">
				<div v-if="storeGlobal.permisos.sponsors.showed" class="col-2 d-none d-md-block"></div>
				<div class="col-12 col-md-6 content">
					<div class="row">
						<div class="col">
							<div v-if="windowLocation.includes('bodosound')" class="item">
								BODO FESTIVALS S.L. © {{ new Date().getFullYear() }}
							</div>
							<div v-else class="item">
								<a href="https://mygocu.com"> MYGOCU </a>
								© {{ new Date().getFullYear() }}
							</div>
						</div>
					</div>
					<div v-if="!windowLocation.includes('bodosound')" class="row">
						<div class="col">
							<div class="item">
								{{ $t('footer.copyright.address') }}
							</div>
						</div>
					</div>
					<div class="row justify-content-center">
						<div class="col-6 col-md-auto">
							<div class="item">
								<a :href="storeGlobal.valores.avisos_legales" target="_blank" class="text-white">
									{{ $t('footer.copyright.legal') }}
								</a>
							</div>
						</div>
						<div class="col-6 col-md-auto">
							<div @click="goCondicionesGenerales">
								<div class="item">
									{{ $t('footer.copyright.conditions') }}
								</div>
							</div>
						</div>
						<div class="col-6 col-md-auto">
							<div @click="goPoliticaCookies">
								<div class="item">
									{{ $t('footer.copyright.cookies') }}
								</div>
							</div>
						</div>
						<div class="col-6 col-md-auto">
							<div @click="goFAQs">
								<div class="item">
									{{ $t('footer.copyright.faqs') }}
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center mt-2">
						<div v-if="!windowLocation.includes('bodosound')" class="col">
							Powered by <a href="https://mygocu.com">Mygocu</a>
						</div>
					</div>
				</div>
				<div v-if="storeGlobal.permisos.sponsors.showed" class="col-4 sponsors d-none d-md-block">
					<div class="row">
						<div class="col m-2" role="button" aria-label="Link estacion Aramon">
							<a :href="aramon" target="_blank">
								<img src="../assets/home/aramon.svg" alt="Aramon Estación" title="Aramon Estación" />
							</a>
						</div>

						<div class="col m-2" role="button">
							<a :href="grandvalira" target="_blank" aria-label="Link estacion GrandValira">
								<img src="../assets/home/grandvalira.svg" alt="Grandvalira Estación" title="Grandvalira Estación" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { appGlobalStore } from 'src/store_pinia/app';
	import {
		facebook,
		instagram,
		twitter,
		aramon,
		grandvalira,
		youtube,
		linkedin,
		tiktok,
		whatsapp,
	} from 'src/constants/socialMedias.js';
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';
	import { BlogLanding, FAQS, TrabajaConNosotros, ViajesGrupoCatalogo } from '@/router/RouterNames';
	import router from '@/router';

	const { t } = useI18n();

	const storeGlobal = appGlobalStore();
	const windowLocation = computed(() => window.location.origin);
	const bodoDomain = 'https://app.bodosound.com';

	function goCondicionesGenerales() {
		if (window.location.origin === bodoDomain) {
			window.open('https://bodosound.com/terminos-y-condiciones-del-servicio/', '_blank');
		} else {
			router.push({ name: 'PoliticaPrivacidad' });
		}
	}

	function goPoliticaCookies() {
		if (window.location.origin === bodoDomain) {
			window.open('https://bodosound.com/privacy-policy/', '_blank');
		} else {
			router.push({ name: 'PoliticaCookies' });
		}
	}

	function goFAQs() {
		router.push({ name: FAQS });
	}

	const footerData = computed(() => {
		return {
			us: {
				title: t('footer.us.title'),
				items: [
					{
						label: t('footer.us.contact'),
						link: whatsapp,
						show: storeGlobal.permisos.contacto.showed,
					},
					{
						label: t('footer.us.work'),
						routeName: TrabajaConNosotros,
						show: storeGlobal.permisos.nosotros.showed,
					},
					{
						label: t('footer.us.blog'),
						routeName: BlogLanding,
						show: storeGlobal.permisos.blog.showed,
					},
				],
			},
			groups: {
				title: t('footer.saleGroups.title'),
				items: [
					{
						label: t('footer.saleGroups.school'),
						routeName: ViajesGrupoCatalogo,
						key: 'colegios',
					},
					{
						label: t('footer.saleGroups.family'),
						routeName: ViajesGrupoCatalogo,
						key: 'familias',
					},
					{
						label: t('footer.saleGroups.university'),
						routeName: ViajesGrupoCatalogo,
						key: 'universitarios',
					},
					{
						label: t('footer.saleGroups.company'),
						routeName: ViajesGrupoCatalogo,
						key: 'trabajadores',
					},
				],
			},
			social: {
				title: t('footer.social'),
				items: [
					{
						link: facebook,
						ariaLabel: 'Link Ski&Night Facebook',
						icon: 'fa-brands fa-facebook-f fa-xl',
					},
					{
						link: instagram,
						ariaLabel: 'Link Ski&Night Instragram',
						icon: 'fa-brands fa-instagram fa-xl',
					},
					{
						link: twitter,
						ariaLabel: 'Link Ski&Night Twitter',
						icon: 'fa-brands fa-twitter fa-xl',
					},
					{
						link: linkedin,
						ariaLabel: 'Link Ski&Night Linkedin',
						icon: 'fa-brands fa-linkedin fa-xl',
					},
					{
						link: tiktok,
						ariaLabel: 'Link Ski&Night Tiktok',
						icon: 'fa-brands fa-tiktok fa-xl',
					},
					{
						link: youtube,
						ariaLabel: 'Link Ski&Night Youtube',
						icon: 'fa-brands fa-youtube fa-xl',
					},
				],
			},
		};
	});
</script>

<style lang="scss" scoped>
	.footer {
		.content,
		.copyright {
			max-width: 1440px;
			margin: auto;
		}
	}

	.footer-san {
		.content,
		.copyright {
			max-width: 1440px;
			margin: auto;
		}
		background-image: url('src/assets/fotos/FooterSeaAndNight.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.item-social {
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			i {
				color: white;
			}
		}
		padding: 8px;
	}

	.item {
		margin-top: 0.8rem !important;
		cursor: pointer;
	}
</style>
