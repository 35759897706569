<template>
	<section class="footer">
		<div class="price-box">
			<p class="price-from">{{ $t('general.desde') }}</p>
			<p class="price">
				{{ setNumberToNaturalRounded(props.price) }}€ <span class="price-text">{{ perPerson }}</span>
			</p>
		</div>
		<div class="button-box">
			<MyGocuButton class="button" type="secondary" :label="$t('general.reservar')" @click="goToAlojamientos"/>
		</div>
	</section>
</template>

<script setup>
	import { computed, toRefs } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRouter, useRoute } from 'vue-router';
	import { setNumberToNaturalRounded } from '@/helpers/numberUtils';
	import {
		GruposReservaAlojamientos,
		GruposReservaFormularioUnirse,
		GruposReservaFormularioUnirseSinAlojamiento
	} from '@/router/RouterNames';
	import MyGocuButton from '@/components/common/buttons/MyGocuButton';

	const { t } = useI18n();
	const route = useRoute();
	const router = useRouter();

	const props = defineProps({
		viajeId: {
			type: Number,
			required: true,
			default: null,
		},
		codigoSinHabitacion: {
			type: String,
			required: false,
			default: '',
		},
		hasAlojamiento: {
			type: Boolean,
			required: true
		},
		price: {
			type: Number,
			default: 0,
		},
		loadingGlobal: {
			type: Boolean,
			default: false,
		},
	});

	const { loadingGlobal } = toRefs(props);

	const perPerson = computed(() => {
		return `/${t('general.porPersona').match(/pers/)[0]}`;
	});

	function goToAlojamientos() {
		try {
			loadingGlobal.value = true;
			if (props.hasAlojamiento && props.codigoSinHabitacion) {
					router.push({
						name: GruposReservaFormularioUnirse,
						query: { grupo: route.query.grupo },
						params: {
							codigoGrupo: props.codigoSinHabitacion,
							viajeId: props.viajeId,
						},
					});
			} else if (props.hasAlojamiento) {
				router.push({
					name: GruposReservaAlojamientos,
					query: { grupo: route.query.grupo },
					params: {
						viajeId: props.viajeId,
					},
				});
			} else {
				router.push({
					name: GruposReservaFormularioUnirseSinAlojamiento,
					query: { grupo: route.query.grupo },
					params: { viajeId: props.viajeId }
				});
			}
		} finally {
			loadingGlobal.value = false;
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		.footer {
			@extend .card-footer, .d-flex, .justify-content-between, .align-items-end, .rounded-bottom-4, .border-0, .pb-3;
			background: white;

			@include media-breakpoint-up(md) {
				height: 52px;
			}

			.price-box {
				@extend .position-relative;
				color: var(--bs-primary);
				.price-from {
					@extend .position-absolute, .m-0;
					bottom: 1.5rem;
					font-size: 0.625rem;
				}
				.price {
					@extend .card-text, .fw-semibold;
					font-size: 1.25rem;
					.price-text {
						@extend .fst-normal;
						font-size: 0.5rem;
					}
				}
			}
			.button-box {
				.button {
					@extend .btn, .btn-primary, .border-0, .rounded-4;
					box-shadow: 0px 1px 2px rgba(33, 36, 68, 0.35);
					color: var(--bs-secondary);
					font-size: 0.875rem;
					font-weight: 500;
					height: 37px;
					width: 103px;
				}
			}
		}
	}
</style>
