<template>
	<div class="d-flex justify-content-center spinner-position align-items-center py-5">
		<div class="spinner-grow" role="status">
			<span class="sr-only" />
		</div>
	</div>
</template>

<style lang="css" scoped>
	.spinner-grow {
		background-color: var(--bs-primary);
		color: var(--bs-primary);
	}
</style>
